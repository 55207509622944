import { convertUtf8ToHex } from '@walletconnect/utils'
import walletType from '../constant/WalletTypeConstant'

const signByMetamaskExtension = ({ message, address }) => {
    var ethUtil = require('ethereumjs-util')

    return new Promise((myResolve, myReject) => {
        console.log('address', address)
        console.log('message=', message)

        var prefix = Buffer.from('\x19Ethereum Signed Message:\n')
        var messagePrefixedBuf = Buffer.concat([
            prefix,
            Buffer.from(String(message.length)),
            Buffer.from(message, 'utf-8'),
        ])
        console.log('messagePrefixed', messagePrefixedBuf.toString('hex'))

        var params = [message, address]

        var method = 'personal_sign'
        window.ethereum.sendAsync(
            {
                method,
                params,
                address,
            },
            function (err, result) {
                if (!err) {
                    console.log(result)

                    var sig = ethUtil.fromRpcSig(result.result)
                    var signResult = {
                        callerAddress: address,
                        r: sig.r.toString('hex'),
                        s: sig.s.toString('hex'),
                        v: Buffer.from([sig.v]).toString('hex'),
                    }
                    console.log('sign signResult=', signResult)
                    myResolve(signResult)
                } else {
                    // user deny code is 4001
                    console.log('Sign Data Error', err)
                    if (err.code === 4001) {
                        myReject({ status: 'error', message: 'User denied message signature' })
                    } else if (err.code === 4100) {
                        // mostly happen when address on wallet not match the one in message request
                        myReject({ status: 'error', message: 'Request params invalid' })
                    } else {
                        myReject(err)
                    }
                }
            },
        )
    })
}

const signByWalletConnect = ({ connector, message, address }) => {
    console.log('signByWalletConnect()', { message, address })
    var ethUtil = require('ethereumjs-util')
    // encode message (hex)
    const hexMsg = convertUtf8ToHex(message)
    // eth_sign params
    const msgParams = [hexMsg, address]

    return new Promise((myResolve, myReject) => {
        if (!connector) {
            myReject('Connector not create')
        }
        connector
            .signPersonalMessage(msgParams)
            .then((result) => {
                console.log(result)
                var sig = ethUtil.fromRpcSig(result)
                const signatureObject = {
                    callerAddress: address,
                    r: sig.r.toString('hex'),
                    s: sig.s.toString('hex'),
                    v: Buffer.from([sig.v]).toString('hex'),
                }
                console.log('signByWalletConnect() signatureObject=', signatureObject)
                myResolve(signatureObject)
            })
            .catch((error) => {
                console.log('Sign Data error=', error)
                if (error.message?.includes('User denied')) {
                    myReject({ status: 'error', message: 'User denied message signature' })
                } else if (error.message?.includes('Invalid parameters')) {
                    // mostly happen when address on wallet not match the one in message request
                    myReject({ status: 'error', message: 'Request params invalid' })
                } else {
                    myReject(error)
                }
            })
    })
}

const signData = ({ message, address, connector, type }) => {
    if (type === walletType.metamaskExtension) {
        return signByMetamaskExtension({ message, address })
    } else {
        return signByWalletConnect({ connector, message, address })
    }
}

export { signData, signByMetamaskExtension }
