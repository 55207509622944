export default {
    somethingWentWrong: 'somethingWentWrong',
    apiTokenInvalid: 'apiTokenInvalid',
    userDenySignature: 'userDenySignature',
    signFail: 'signFail',
    failedToGetCmdParam: 'failedToGetCmdParam',
    walletNotConnected: 'walletNotConnected',
    txCountOverLimit: 'txCountOverLimit',
    addressNotMatch: 'addressNotMatch',
}
