const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

const isEmptyString = (value) => {
    return !value || value === ''
}

const formatBackendEnvType = (chainName) => {
    let env = ''
    switch (chainName.toLocaleLowerCase()) {
        case 'ethereum':
            env = 'ETHEREUM'
            break
        case 'goerli':
            env = 'ETHEREUM'
            break
        case 'polygon':
            env = 'POLYGON'
            break
        case 'mumbai':
            env = 'POLYGON'
            break
        default:
            env = 'ETHEREUM'
            break
    }
    return env
}

const stringToByteArray = (str) => {
    var myBuffer = []
    var buffer = Buffer.from(str, 'utf8')
    for (var i = 0; i < buffer.length; i++) {
        myBuffer.push(buffer[i])
    }
    return myBuffer
}

const extractProjectKey = (projectKeyString) => {
    const indexOfFirstUnderline = projectKeyString.indexOf('_')
    const address = projectKeyString.slice(0, indexOfFirstUnderline)
    const indexOfLastUnderline = projectKeyString.lastIndexOf('_')
    const projectName = projectKeyString.slice(indexOfFirstUnderline + 1, indexOfLastUnderline)
    return { address, projectName }
}

export { validateEmail, isEmptyString, formatBackendEnvType, stringToByteArray, extractProjectKey }
