import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Stack, Typography, Card, CardContent } from '@mui/material'
import BlockchainPreview from './common/BlockchainPreview'

const ChoosedBlockchain = ({ info, status }) => {
    const { t } = useTranslation()
    return (
        <Stack spacing={0.5} px={2}>
            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                {t('Blockchain')}
            </Typography>
            <Card variant="outlined">
                <CardContent sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                    <BlockchainPreview info={info} status={status} />
                </CardContent>
            </Card>
        </Stack>
    )
}

ChoosedBlockchain.propTypes = {
    info: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
}

export default ChoosedBlockchain
