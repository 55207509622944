import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { styled, Stack, Box, Typography, CircularProgress, useMediaQuery } from '@mui/material'

import QuantityInput from './QuantityInput'
import ChoosedBlockchain from './ChoosedBlockchain'
import EstimatedCostInfoAlert from './EstimatedCostInfoAlert/EstimatedCostInfoAlert'
import NftInfo from './NftInfo/NftInfo'
import FooterButton from '../../common/FooterButton'
import PendingForMintMessage from './PendingForMintMessage'
import { ReactComponent as MintIcon } from '../../../image/ic_black_mint.svg'

import { UserInfoContext } from '../../../App'
import axios from '../../../action/MintServerApi'
import { isEmptyString, formatBackendEnvType, stringToByteArray } from '../../../util/stringUtil'
import { connectMetaMaskAccount } from '../../../util/metaMaskUtil'
import { getChainName, getContractAddress } from '../../../util/envUtil'
import { mintStageConstant } from '../../../constant/StageConstant'
import ChainId from '../../../constant/ChainId'
import ChainInfo from '../../../constant/ChainInfo'
import walletType from '../../../constant/WalletTypeConstant'
import { createWeb3AndContractService, web3, nftContract, accountInfo, disconnect } from '../../../util/walletUtil'
import errorConstant from './constant/MintErrorConstant'
import ErrorMessage from '../../common/ErrorMessage'
import RwdContainer from '../../common/RwdContainer'
import HintModal, { modalType } from '../../common/HintModal'
import { isMobileDevice } from '../../../util/mobileUtil'

const CustomContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('laptop')]: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
    },
}))

const MintPage = () => {
    const cookies = new Cookies()
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const chosenEnvInParam = isEmptyString(searchParams.get('chain')) ? 'ETHEREUM' : searchParams.get('chain')
    const chainName = getChainName(chosenEnvInParam)
    const contractAddress = getContractAddress(chosenEnvInParam)
    const backendEnvType = formatBackendEnvType(chosenEnvInParam)
    const isMobile = isMobileDevice()
    const isEthereum = chosenEnvInParam?.toLocaleLowerCase() === 'ethereum'
    const gasPriceMultiplier = isEthereum ? 1.1 : 2.0
    const maxPriorityFeePerGasInWei = isEthereum ? 2500000000 : 30000000000

    const [userInfo] = useContext(UserInfoContext)
    const walletAddress = userInfo?.address
    const [walletBal, setWalletBal] = useState('')
    const [wholeEstimatedFee, setWholeEstimatedFee] = useState('')
    const [status, setStatus] = useState(mintStageConstant.confirmMintInfo)
    const [isLoadingPage, setIsLoadingPage] = useState(false)
    const [info, setInfo] = useState({
        nftName: '',
        description: '',
        fileName: '',
        previewImgUrl: '',
        previewYoutubeUrl: '',
        previewAnimationUrl: '',
        data: '',
        account: '',
        id: '',
        quantity: 1,
        chain: chosenEnvInParam,
    })
    const [isMintProcessing, setIsMintProcessing] = useState(false)
    const [disabledBtn, setDisabledBtn] = useState(false)
    const [error, setError] = useState({
        title: 'somethingWentWrong',
        description: 'tryAgainLaterHint',
        buttonText: 'Retry',
        hideButton: false,
        buttonAction: () => reload(),
    })

    const [modalContent, setModalContent] = useState({
        type: modalType.warn,
        open: false,
        title: '',
        description: '',
        closable: false,
        showRetryButton: false,
        buttonText: 'Close',
    })
    const [isRefreshBalance, setIsRefreshBalance] = useState(false)

    // New method
    const createWeb3Service = async () => {
        const isLogin = userInfo && userInfo.address !== '' && userInfo.email !== ''
        if (!isLogin) {
            console.log('user not login yet')
            onError(errorConstant.userNotLogin)
        } else {
            const selectWalletType = cookies.get('select-wallet-type', { path: '/' })
            await createWeb3AndContractService(selectWalletType, contractAddress)
            if (accountInfo.address !== userInfo.address) {
                onError(errorConstant.connectAccountIncorrect)
            }
        }
    }

    const initialPage = () => {
        createWeb3Service().then(() => {
            // can only do the following step after web3 initial
            fetchWalletBalance()
            fetchNftInfo()
        })
    }

    // initial Web3
    useEffect(() => {
        initialPage()
    }, [])

    const roundWalletBal = (bal) => {
        return Math.floor(bal * 100000) / 100000
    }

    const fetchWalletBalance = () => {
        setIsRefreshBalance(true)
        web3.eth
            .getBalance(walletAddress)
            .then((bal) => {
                const balInEther = web3.utils.fromWei(bal, 'ether')
                const balance = roundWalletBal(balInEther)
                console.log('balance: ', balance)
                setWalletBal(balance)
                setIsRefreshBalance(false)
            })
            .catch((error) => {
                console.log('fetchWalletBalance() error:', error)
                setModalContent({
                    type: modalType.warn,
                    open: true,
                    closable: true,
                    description: 'failToRefreshWalletBalance',
                })
            })
    }

    const checkChain = async () => {
        const connectChainId = await web3.eth.getChainId()
        let expectChainId = ChainId.MAINNET
        if (process.env.REACT_APP_CHAIN.toLocaleLowerCase() === 'testnet') {
            if (chosenEnvInParam.toLocaleLowerCase() === 'polygon') {
                expectChainId = ChainId.MUMBAI
            } else {
                expectChainId = ChainId.GOERLI
            }
        } else {
            if (chosenEnvInParam.toLocaleLowerCase() === 'polygon') {
                expectChainId = ChainId.POLYGON
            } else {
                expectChainId = ChainId.MAINNET
            }
        }

        if (connectChainId !== expectChainId) {
            return false
        }
        return true
    }

    const fetchDescription = (path, apiToken, address, projectKey, backendEnvType) => {
        axios
            .post(path, {
                apiToken,
                address,
                projectKey,
                env: backendEnvType,
            })
            .then((res) => {
                const { id, name, description, image, youtubeUrl, animationUrl, data, account } = res.data
                const dataBytes = stringToByteArray(data)
                const prefixedHexId = '0x' + id

                setInfo({
                    ...info,
                    nftName: name,
                    description: description,
                    previewImgUrl: image,
                    previewYoutubeUrl: youtubeUrl,
                    previewAnimationUrl: animationUrl,
                    data: dataBytes,
                    account: account,
                    id: prefixedHexId,
                })
            })
            .catch((error) => {
                console.log('fetch mint info fail, error=', error)
                let errorRes = error.response.data
                if (errorRes.message.includes('invalid api token')) {
                    // 400 error
                    onError(errorConstant.apiTokenInvalid)
                } else if (errorRes.message.includes('not found mapping receipt')) {
                    // 404 error
                    onError(errorConstant.missingInfoParam)
                } else {
                    onError(errorConstant.somethingWentWrong)
                }
            })
    }

    const fetchNftInfo = async () => {
        setStatus(mintStageConstant.confirmMintInfo)
        setIsLoadingPage(true)
        if (walletAddress && walletAddress !== '') {
            const apiTokenInParam = searchParams.get('apiToken')
            const projectKeyInParam = searchParams.get('projectKey')
            if (!apiTokenInParam) {
                console.log('api token invalid')
                onError(errorConstant.apiTokenInvalid)
            } else if (!projectKeyInParam) {
                onError(errorConstant.missingInfoParam)
            } else {
                if (await checkChain()) {
                    // check data status
                    // result types: NEED_UPLOAD_NFT_METADATA, NEED_UPLOAD_RAW_DATA, NEED_CREATE_DESCRIPTION, CREATED_DESCRIPTION, MINTED
                    axios
                        .post(`/rest/nft/mint/get-status`, {
                            apiToken: apiTokenInParam,
                            address: walletAddress,
                            projectKey: projectKeyInParam,
                            env: backendEnvType,
                        })
                        .then((res) => {
                            console.log('get mint status res=', res)
                            const { mintStatus } = res.data
                            if (mintStatus === 'NEED_CREATE_DESCRIPTION') {
                                fetchDescription(
                                    `/rest/nft/mint/create-description`,
                                    apiTokenInParam,
                                    walletAddress,
                                    projectKeyInParam,
                                    backendEnvType,
                                )
                            } else if (mintStatus === 'CREATED_DESCRIPTION') {
                                fetchDescription(
                                    `/rest/nft/list/get-description`,
                                    apiTokenInParam,
                                    walletAddress,
                                    projectKeyInParam,
                                    backendEnvType,
                                )
                            } else if (mintStatus === 'MINT') {
                                setModalContent({
                                    type: modalType.plainTextInfo,
                                    open: true,
                                    closable: true,
                                    title: 'existingMintTransactionTitle',
                                    description: 'existingMintTransactionDescription',
                                    showRetryButton: true,
                                    buttonText: 'CONTINUE',
                                })

                                fetchDescription(
                                    `/rest/nft/list/get-description`,
                                    apiTokenInParam,
                                    walletAddress,
                                    projectKeyInParam,
                                    backendEnvType,
                                )
                            } else if (
                                mintStatus === 'CONFIRM_MINT' ||
                                mintStatus === 'MINTED' ||
                                mintStatus === 'PENDING_MINT'
                            ) {
                                onError(errorConstant.dataAlreadyMinted)
                            } else if (mintStatus === 'NEED_UPLOAD_NFT_METADATA') {
                                onError(errorConstant.metadataMissing)
                            } else if (mintStatus === 'NEED_UPLOAD_RAW_DATA') {
                                onError(errorConstant.rawDataMissing)
                            } else {
                                onError(errorConstant.somethingWentWrong)
                            }
                        })
                        .catch((error) => {
                            console.log('Mint get-status error', error)
                            let errorRes = error.response.data
                            if (errorRes?.message?.includes('invalid api token')) {
                                onError(errorConstant.apiTokenInvalid)
                            } else {
                                onError(errorConstant.somethingWentWrong)
                            }
                        })
                        .finally(() => {
                            setIsLoadingPage(false)
                        })
                } else {
                    console.log('chain incorrect')
                    onError(errorConstant.incorrectChain)
                }
            }
        }
    }

    const reload = () => {
        window.location.reload()
    }

    useEffect(() => {
        if (web3 && nftContract && info && info.id && info.id !== '' && info.quantity !== '') {
            console.log({ web3, info })

            handleEstimateTxFee(info.account, info.id, info.quantity, info.data)
                .then((result) => {
                    setWholeEstimatedFee(result)
                })
                .catch((error) => {
                    console.log('estimateTxFee error', error)
                    setWholeEstimatedFee(null)
                })
        }
    }, [info])

    const handleEstimateTxFee = async (account, id, quantity, data) => {
        let estimatedFeeInEther = ''
        if (web3) {
            const estimatedGas = await web3.eth.estimateGas({
                from: account,
                to: contractAddress,
                data: nftContract.methods.mint(account, id, quantity, data).encodeABI(),
            })
            const gasPrice = await web3.eth.getGasPrice()

            const txFeeInWei = Number(estimatedGas * gasPrice * gasPriceMultiplier).toFixed(0)
            estimatedFeeInEther =
                Number(web3.utils.fromWei(txFeeInWei.toString(), 'ether')) +
                Number(web3.utils.fromWei(maxPriorityFeePerGasInWei.toString(), 'ether'))
            console.log('estimatedFeeInEther: ', estimatedFeeInEther)
        }
        return estimatedFeeInEther
    }

    const isWalletConnected = async () => {
        if (cookies.get('select-wallet-type', { path: '/' }) === walletType.walletConnect) {
            return web3.currentProvider.connected
        } else {
            const metaMaskAccountInfo = await connectMetaMaskAccount()
            if (metaMaskAccountInfo.address && metaMaskAccountInfo.address !== '') {
                return true
            }
        }
        return false
    }

    const sendStartMintInfo = (info) => {
        const requestData = {
            tokenId: info.id.slice(2),
            ownerAddress: info.account,
            ownerAmount: info.quantity,
            contractAddress: contractAddress,
            apiToken: searchParams.get('apiToken'),
            address: walletAddress,
            env: backendEnvType,
        }
        return axios.post(`/rest/nft/mint-info`, requestData)
    }

    const patchMintStatusConstant = {
        CONFIRM_MINT: 'CONFIRM_MINT',
        PENDING_MINT: 'PENDING_MINT',
    }

    const patchMintInfo = (tokenId, walletAddress, status) => {
        const apiToken = searchParams.get('apiToken')
        axios.patch(`/rest/nft/mint-info/${tokenId}/${backendEnvType}?address=${walletAddress}&apiToken=${apiToken}`, {
            status: status,
        })
    }

    const onUserConfirmMintOnWallet = (tokenId, walletAddress) => {
        patchMintInfo(tokenId, walletAddress, patchMintStatusConstant.CONFIRM_MINT)
    }

    const mintDone = (tokenId, walletAddress) => {
        setIsMintProcessing(false)
        patchMintInfo(tokenId, walletAddress, patchMintStatusConstant.PENDING_MINT)
        setStatus(mintStageConstant.mintSuccess)
    }

    const [showRetry, setShowRetry] = useState(false)
    const [retryCountDown, setRetryCountDown] = useState(3)

    useEffect(() => {
        if (retryCountDown <= 0) {
            setShowRetry(true)
        }
    }, [retryCountDown])

    useEffect(() => {
        if (status === mintStageConstant.confirmMintInfo) {
            setShowRetry(false)
            setRetryCountDown(3)
        }
    }, [status])

    const delayAndRetry = () => {
        const interval = setInterval(() => {
            setRetryCountDown((previousValue) => {
                if (previousValue <= 0) {
                    clearInterval(interval)
                    return 0
                }
                return previousValue - 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }

    const onRetryMintClick = () => {
        setStatus(mintStageConstant.confirmMintInfo)
        mintNFT()
    }

    const getTxParams = async (nftInfo) => {
        // Returns the current gas price oracle. The gas price is determined by the last few blocks median gas price.
        const baseGasPrice = await web3.eth.getGasPrice()
        console.log('getTxParams() current gas price oracle:', baseGasPrice)
        const txParams = {
            from: nftInfo.account,
            to: contractAddress,
            gas: web3.utils.numberToHex(process.env.REACT_APP_GAS_LIMIT),
            gasPrice: baseGasPrice * gasPriceMultiplier,
            maxPriorityFeePerGas: web3.utils.numberToHex(maxPriorityFeePerGasInWei),
            data: nftContract.methods
                .mint(nftInfo.account, nftInfo.id, Number(nftInfo.quantity), nftInfo.data)
                .encodeABI(),
        }
        console.log('gasPrice:', txParams.gasPrice)
        console.log('getTxParams()', { txParams })
        return txParams
    }

    const mintNFT = async () => {
        setIsMintProcessing(true)
        if (await isWalletConnected()) {
            if (await checkChain()) {
                setModalContent({
                    type: modalType.plainTextInfo,
                    open: true,
                    closable: false,
                    title: 'mintingRequestSendingTitle',
                    description: 'checkWalletRequestMessage',
                    showRetryButton: true,
                })
                const txParams = await getTxParams(info)
                console.log('mint txParams=', txParams)

                if (isMobile) {
                    // delay and show try again button
                    delayAndRetry()
                }

                sendStartMintInfo(info)
                    .then(() => {
                        web3.eth
                            .sendTransaction(txParams)
                            .on('transactionHash', function () {
                                // This will trigger when user click confirm button
                                onUserConfirmMintOnWallet(info.id.slice(2), info.account)
                                setModalContent({
                                    type: modalType.success,
                                    open: true,
                                    closable: false,
                                    title: 'signatureSentAndTransmittingTitle',
                                    description: 'dontExitScreenHint',
                                    showRetryButton: false,
                                })
                            })
                            .on('error', function (error) {
                                handleModalClose()
                                console.log('send mint transaction error: ', { error })
                                // when there is error happen on wallet side
                                if (
                                    error.message.includes('User rejected the transaction') ||
                                    error.message.includes('User denied transaction')
                                ) {
                                    onError(errorConstant.userDenySignature)
                                } else if (error.message.includes('nonce')) {
                                    onError(errorConstant.nonceProblem)
                                } else {
                                    onError(errorConstant.somethingWentWrong)
                                }
                            })
                            .then(function (receipt) {
                                console.log('receipt: ', receipt)
                                handleModalClose()
                                mintDone(info.id.slice(2), info.account)
                            })
                    })
                    .catch((error) => {
                        console.log('sendStartMintInfo error:', error)
                        onError(errorConstant.sendMintInfoFail)
                    })
            } else {
                onError(errorConstant.incorrectChain)
            }
        } else {
            onError(errorConstant.walletNotConnected)
        }
    }

    const handleChangeQuantity = (event) => {
        let tempInfo = { ...info }
        let quantity = event.target.value
        tempInfo['quantity'] = quantity
        setInfo(tempInfo)
    }

    const handleBtnDisabled = (toDisable) => {
        setDisabledBtn(toDisable)
    }

    const onError = (type) => {
        setIsMintProcessing(false)
        if (type === errorConstant.apiTokenInvalid) {
            setError({
                title: 'connectionFailTitle',
                description: 'sessionExpireGoBackToApp',
                hideButton: true,
            })
        } else if (type === errorConstant.dataAlreadyMinted) {
            setError({
                title: 'nftAlreadyMintTitle',
                description: 'cannotMintSameDataHint',
                hideButton: true,
            })
        } else if (type === errorConstant.userNotLogin) {
            setError({
                title: 'userNotLoginTitle',
                description: 'goBackToAppAndLogin',
                hideButton: true,
            })
        } else if (type === errorConstant.connectAccountIncorrect) {
            setError({
                title: 'accountNotMatchTitle',
                description: 'accountNotMatchLoginInfoTryAgain',
                hideButton: false,
                buttonText: 'Retry',
                buttonAction: () => handleRetryConnectWallet(),
            })
        } else if (type === errorConstant.missingInfoParam) {
            setError({
                title: 'failedToGetInformation',
                description: 'missingInfoGoBackToApp',
                hideButton: true,
            })
        } else if (type === errorConstant.metadataMissing) {
            setError({ title: 'metadataNotUploadTitle', description: 'goBackToAppPrepareDataHint', hideButton: true })
        } else if (type === errorConstant.rawDataMissing) {
            setError({ title: 'rawDataNotUploadTitle', description: 'goBackToAppPrepareDataHint', hideButton: true })
        } else if (type === errorConstant.nonceProblem) {
            setError({
                title: 'nonceNotValid',
                description: 'rejectTransactionAndTryAgain',
                hideButton: false,
                buttonAction: () => reload(),
            })
        } else if (type === errorConstant.incorrectChain) {
            setError({
                title: 'incorrectChainConnectedTitle',
                description: {
                    text: 'incorrectChainConnectedDescription',
                    value: chainName,
                },
                hideButton: false,
                buttonText: 'addOrSwitchNetworkText',
                buttonAction: () => changeNetwork(chainName),
            })
        } else if (type === errorConstant.walletNotConnected) {
            setError({
                title: 'walletNotConnectedTitle',
                description: 'checkWalletConnectionAndTryAgainHint',
                hideButton: false,
                buttonText: 'Retry',
                buttonAction: () => reload(),
            })
        } else if (type === errorConstant.sendMintInfoFail) {
            setError({
                title: 'cannotStartMintTitle',
                description: 'requestingMintFailTryAgainHint',
                hideButton: false,
                buttonText: 'Retry',
                buttonAction: () => setStatus(mintStageConstant.confirmMintInfo),
            })
        } else if (type === errorConstant.userDenySignature) {
            setError({
                title: 'userRejectTransaction',
                description: 'cannotMintWhenRejectHint',
                hideButton: false,
                buttonText: 'Retry',
                buttonAction: () => setStatus(mintStageConstant.confirmMintInfo),
            })
        } else {
            setError({
                title: 'somethingWentWrong',
                description: 'tryAgainLaterHint',
                hideButton: false,
                buttonAction: () => reload(),
            })
        }
        setStatus(mintStageConstant.alertMessage)
    }

    const handleModalClose = () => {
        setModalContent({ ...modalContent, open: false })
    }

    const handleRetryConnectWallet = () => {
        disconnect()
        setStatus(mintStageConstant.confirmMintInfo)
        initialPage()
    }

    const addEthereumChain = (chainInfo) => {
        return web3.currentProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: chainInfo.idInHex,
                    chainName: chainInfo.chainName,
                    nativeCurrency: {
                        name: chainInfo.currencyName,
                        symbol: chainInfo.currencySymbol,
                        decimals: 18,
                    },
                    blockExplorerUrls: [chainInfo.blockExplorerUrl],
                    rpcUrls: [chainInfo.rpcUrl],
                },
            ],
        })
    }

    const switchEthereumChain = (chainInfo) => {
        return web3.currentProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainInfo.idInHex }],
        })
    }

    const checkChainChangeAndReload = (expectChainInfo) => {
        // keep checking if chain change for 10 seconds, if changed then reload
        var maxWaitTime = 10000 // ms, equals 10 seconds
        const fetchInterval = 1000 // every 1 seconds
        const interval = setInterval(function () {
            // check chain id to see if chain change
            web3.eth.getChainId(function (err, rec) {
                if (maxWaitTime <= 0) {
                    clearInterval(interval)
                    reload()
                } else {
                    maxWaitTime -= fetchInterval
                }
                if (rec) {
                    if (rec === expectChainInfo.idInDecimal) {
                        clearInterval(interval)
                        reload()
                    }
                }
                if (err) {
                    reload()
                }
            })
        }, fetchInterval)
    }

    const changeNetwork = (chainName) => {
        console.log('changeNetwork()', { chainName })
        const chainInfo = ChainInfo[chainName.toLocaleLowerCase()]
        // Goerli and ethereum originally exist in list, so don't need to add
        if (chainName === 'GOERLI' || chainName === 'ETHEREUM') {
            switchEthereumChain(chainInfo).then(() => {
                checkChainChangeAndReload(chainInfo)
            })
        } else {
            // If mumbai or polygon, add network. If already exist, it will automatically change to switch request
            addEthereumChain(chainInfo)
                .then(() => checkChainChangeAndReload(chainInfo))
                .catch((error) => {
                    // in case endpoint is dead, try again
                    if (
                        error &&
                        error.message?.includes('eth_chainId ')
                        // whole message: "Request for method 'eth_chainId on https://rpc-mumbai.matic.today failed"
                    ) {
                        addEthereumChain(chainInfo).then(() => checkChainChangeAndReload(chainInfo))
                    }
                })
        }
    }
    const isLaptopScreen = useMediaQuery((theme) => theme.breakpoints.up('laptop'))

    return (
        <>
            {status !== mintStageConstant.alertMessage && (
                <RwdContainer>
                    <>
                        <CustomContainer>
                            <Stack spacing={2} mb={3}>
                                {status === mintStageConstant.confirmMintInfo && (
                                    <Box mt={3} px={2} display="flex" alignItems="center" gap={1}>
                                        <MintIcon />
                                        <Typography variant="h4" color="text.primary">
                                            {t('mintNftTitle')}
                                        </Typography>
                                    </Box>
                                )}
                                {status === mintStageConstant.mintSuccess && <PendingForMintMessage />}
                                {isLoadingPage ? (
                                    <Box display="flex" justifyContent="center">
                                        <CircularProgress size={25} />
                                    </Box>
                                ) : (
                                    <>
                                        {status === mintStageConstant.confirmMintInfo && (
                                            <>
                                                <Box
                                                    sx={{
                                                        display: isLaptopScreen ? 'flex' : null,
                                                        alignItems: isLaptopScreen ? 'flex-start' : null,
                                                    }}
                                                >
                                                    <Box width={isLaptopScreen ? '50%' : '100%'}>
                                                        <QuantityInput
                                                            info={info}
                                                            handleChangeQuantity={handleChangeQuantity}
                                                            handleBtnDisabled={handleBtnDisabled}
                                                        />
                                                    </Box>
                                                    <Box
                                                        mt={isLaptopScreen ? null : 2}
                                                        width={isLaptopScreen ? '50%' : '100%'}
                                                    >
                                                        <ChoosedBlockchain info={info} status={status} />
                                                    </Box>
                                                </Box>
                                                <EstimatedCostInfoAlert
                                                    info={info}
                                                    wholeEstimatedFee={wholeEstimatedFee}
                                                    walletBal={walletBal}
                                                    isRefreshBalance={isRefreshBalance}
                                                    fetchWalletBalance={fetchWalletBalance}
                                                />
                                            </>
                                        )}
                                        {status !== mintStageConstant.alertMessage && (
                                            <NftInfo info={info} status={status} />
                                        )}
                                    </>
                                )}
                            </Stack>
                        </CustomContainer>
                        {status === mintStageConstant.confirmMintInfo && (
                            <FooterButton
                                loading={
                                    isMintProcessing ||
                                    isRefreshBalance ||
                                    !wholeEstimatedFee ||
                                    wholeEstimatedFee === ''
                                }
                                buttonText={'Mint'}
                                onConfirmClick={mintNFT}
                                disabled={
                                    isMintProcessing ||
                                    disabledBtn ||
                                    isRefreshBalance ||
                                    !wholeEstimatedFee ||
                                    wholeEstimatedFee === '' ||
                                    walletBal < wholeEstimatedFee
                                }
                            />
                        )}
                    </>
                </RwdContainer>
            )}
            {status === mintStageConstant.alertMessage && (
                <ErrorMessage
                    title={error.title}
                    description={error.description}
                    buttonText={error.buttonText}
                    hideButton={error.hideButton}
                    onClick={error.buttonAction || reload}
                />
            )}
            <HintModal
                type={modalContent.type}
                open={modalContent.open}
                onClose={modalContent.closable ? handleModalClose : null}
                title={modalContent.title}
                description={modalContent.description}
                showButton={modalContent.closable || (modalContent.showRetryButton && showRetry)}
                buttonText={showRetry ? 'Retry' : modalContent.buttonText}
                buttonAction={showRetry ? onRetryMintClick : handleModalClose}
            />
        </>
    )
}

export default MintPage
