const convertFixedEstimatedFee = (estimatedFee) => {
    let fixedEtherResult
    try {
        fixedEtherResult = Number(estimatedFee).toFixed(6)
    } catch (error) {
        console.log('handleEstimateTxFee() convert to fixed number error:', error)
        fixedEtherResult = estimatedFee
    }
    return fixedEtherResult
}

export { convertFixedEstimatedFee }
