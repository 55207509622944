import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, Stack, Typography, Link, Divider } from '@mui/material'
import MediaPreview from './MediaPreview'
import { mintStageConstant } from '../../../../constant/StageConstant'
import BlockchainPreview from '../common/BlockchainPreview'

const NftInfo = ({ info, status }) => {
    const { t } = useTranslation()
    const isLaptopScreen = useMediaQuery((theme) => theme.breakpoints.up('laptop'))

    const tempDescription = info.description
    const indexOfITMText = tempDescription.indexOf('Powered by ITM')
    const urlAndITMDescription = tempDescription.substr(indexOfITMText)
    const nftDescription = tempDescription.slice(0, indexOfITMText)

    const indexOfMark = urlAndITMDescription.indexOf('[')
    const verifyPageUrl = urlAndITMDescription.slice(
        urlAndITMDescription.indexOf('(') + 1,
        urlAndITMDescription.indexOf(')'),
    )
    const indexOfCloseMark = urlAndITMDescription.indexOf(']')
    const itmDescriptionPrefix = urlAndITMDescription.slice(0, indexOfMark)

    const linkText = urlAndITMDescription.slice(indexOfMark + 1, indexOfCloseMark)
    const textAfterCloseMark = urlAndITMDescription.substr(urlAndITMDescription.indexOf(')') + 1)

    return (
        <Stack spacing={2} px={2}>
            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                {t('aboutThisNFT')}
            </Typography>
            <Stack spacing={2} direction={isLaptopScreen ? 'row-reverse' : 'column'}>
                {(info.previewImgUrl || info.previewYoutubeUrl !== '' || info.previewAnimationUrl !== '') && (
                    <MediaPreview info={info} />
                )}
                <Stack spacing={1.5} maxWidth={isLaptopScreen ? 250 : null}>
                    <Stack spacing={0.5}>
                        <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                            {t('nftName')}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {info.nftName}
                        </Typography>
                    </Stack>
                    {info.description !== '' && (
                        <Stack spacing={0.5} sx={{ minWidth: 250 }}>
                            <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                                {t('Description')}
                            </Typography>
                            <Stack spacing={1}>
                                <Typography variant="body1" color="text.secondary">
                                    {nftDescription}
                                </Typography>
                                {!isLaptopScreen && (
                                    <Typography variant="body1" color="text.secondary" sx={{ wordBreak: 'break-word' }}>
                                        {itmDescriptionPrefix}
                                        <Link
                                            variant="body1"
                                            href={verifyPageUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{ color: 'info.main' }}
                                        >
                                            {linkText}
                                        </Link>
                                        {textAfterCloseMark}
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Stack>
            {isLaptopScreen && (
                <Typography variant="body1" color="text.secondary" sx={{ wordBreak: 'break-word' }}>
                    {itmDescriptionPrefix}
                    <Link
                        variant="body1"
                        href={verifyPageUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: 'info.main' }}
                    >
                        {linkText}
                    </Link>
                    {textAfterCloseMark}
                </Typography>
            )}
            {status === mintStageConstant.mintSuccess && (
                <>
                    <Divider />
                    <Stack spacing={0.5}>
                        <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                            {t('Supply')}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {info.quantity}
                        </Typography>
                    </Stack>
                    <Divider />
                    <Stack spacing={0.5}>
                        <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                            {t('Blockchain')}
                        </Typography>
                        <BlockchainPreview info={info} status={status} />
                    </Stack>
                </>
            )}
        </Stack>
    )
}

NftInfo.propTypes = {
    info: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
}

export default NftInfo
