import Web3 from 'web3'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Cookies from 'universal-cookie'
import { connectMetaMaskAccount } from './metaMaskUtil'
import walletType from '../constant/WalletTypeConstant'
import contractAbi from '../contract/abi/contractABI.json'

const infuraId = process.env.REACT_APP_INFURA_PROJECT_ID

export let connector
export let accountInfo = {
    address: '',
    chainId: 0,
    accounts: [],
}
export let web3
export let nftContract

const disconnect = () => {
    if (connector) {
        try {
            connector.killSession().catch((error) => {
                console.log('connector killSession fail', error)
            })
        } catch (error) {
            console.log('connector killSession fail', error)
            // try to remove it from localStorage
            window.localStorage.removeItem('walletconnect')
        }
    } else {
        window.localStorage.removeItem('walletconnect')
    }
}

const createConnector = () => {
    const cookies = new Cookies()
    cookies.set('select-wallet-type', walletType.walletConnect, { path: '/' })
    const bridge = process.env.REACT_APP_BRIDGE_URL
    const createdConnector = new WalletConnect({
        bridge,
        qrcodeModal: QRCodeModal,
        qrcodeModalOptions: { mobileLinks: ['metamask'] },
    })
    return new Promise((myResolve, myReject) => {
        if (createdConnector && !createdConnector.connected) {
            createdConnector.createSession().then(() => {
                // 1. session update
                createdConnector.on('session_update', (error, payload) => {
                    console.log(`connector.on("session_update")`)
                    if (error) {
                        throw error
                    }

                    // 沒有錯誤則取得chainId以及account資訊
                    const { chainId, accounts } = payload.params[0]
                    const address = accounts[0]
                    accountInfo = { chainId, accounts, address: address.toLocaleLowerCase() }
                    myResolve(accountInfo)
                })
                // 2. connect
                createdConnector.on('connect', (error, payload) => {
                    console.log(`connector.on("connect")`)

                    if (error) {
                        throw error
                    }

                    const { chainId, accounts } = payload.params[0]
                    const address = accounts[0]
                    console.log('onConnect() chainId=', chainId, ',accounts=', accounts)
                    accountInfo = { chainId, accounts, address: address.toLocaleLowerCase() }
                    myResolve(accountInfo)
                })

                // 3. disconnect
                createdConnector.on('disconnect', (error) => {
                    console.log(`connector.on("disconnect")`)
                    if (error) {
                        throw error
                    }
                    myReject({ code: 4001, message: 'User denied to connect account.' })
                    disconnect()
                })

                // update connector status
                if (createdConnector.connected) {
                    const { chainId, accounts } = createdConnector
                    const address = accounts[0]
                    accountInfo = { chainId, accounts, address: address.toLocaleLowerCase() }
                    myResolve(accountInfo)
                }
                connector = createdConnector
            })
        } else if (createdConnector && createdConnector.connected) {
            console.log('connector already connect', createdConnector)
            const { chainId, accounts } = createdConnector
            const address = accounts[0]
            connector = createdConnector
            accountInfo = { chainId, accounts, address: address.toLocaleLowerCase() }
            myResolve(accountInfo)
        }
    })
}

const createWeb3AndContractService = async (selectWalletType, contractAddress) => {
    let web3Instance
    let address

    if (selectWalletType === walletType.walletConnect) {
        // polygon mainnet: 137, mumbai: 80001
        var provider = new WalletConnectProvider({
            infuraId: infuraId,
            rpc: {
                137: `https://polygon-mainnet.infura.io/v3/${infuraId}`,
                80001: `https://polygon-mumbai.infura.io/v3/${infuraId}`,
            },
            bridge: process.env.REACT_APP_BRIDGE_URL,
        })
        await provider.enable()

        provider.on('accountsChanged', (accounts) => {
            console.log('accounts: ', accounts)
            accountInfo = { ...accountInfo, accounts: accounts }
        })

        provider.on('chainChanged', (chainId) => {
            console.log('chainId: ', chainId)
            accountInfo = { ...accountInfo, chainId: chainId }
            window.location.reload()
        })

        provider.on('disconnect', (code, reason) => {
            console.log('disconnect: ', code, reason)
            accountInfo = {
                address: '',
                chainId: 0,
                accounts: [],
            }
        })
        console.log('WC provider: ', provider)
        web3Instance = new Web3(provider)
        const accounts = provider.accounts
        address = accounts[0]
    } else {
        console.log('Using Metamask provider')
        web3Instance = new Web3(window.ethereum)
        const connectAccount = await connectMetaMaskAccount()
        address = connectAccount.address
    }
    web3 = web3Instance
    web3.eth.transactionPollingTimeout = 5000
    accountInfo = { ...accountInfo, address: address.toLocaleLowerCase() }
    nftContract = new web3.eth.Contract(contractAbi, contractAddress)
}

const createMetaMaskProvider = () => {
    const cookies = new Cookies()
    cookies.set('select-wallet-type', walletType.metamaskExtension, { path: '/' })
    return new Promise((myResolve, myReject) => {
        connectMetaMaskAccount()
            .then((result) => {
                const { chainId, address } = result
                accountInfo = { chainId, address: address.toLocaleLowerCase() }
                myResolve(accountInfo)
            })
            .catch((error) => myReject(error))
    })
}

export { createConnector, disconnect, createWeb3AndContractService, createMetaMaskProvider }
