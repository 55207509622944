import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useTheme, Box, Typography } from '@mui/material'

const AddBalanceLink = () => {
    const { t } = useTranslation()
    const theme = useTheme()
    const link = { textDecorationColor: theme.palette.info.main }

    return (
        <Box display="flex">
            <Link to="/buy-crypto" style={link}>
                <Typography variant="body1" color="info.main">
                    {t('howToTopUp')}
                </Typography>
            </Link>
        </Box>
    )
}

export default AddBalanceLink
