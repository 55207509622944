export default {
    apiTokenInvalid: 'apiTokenInvalid',
    userNotLogin: 'userNotLogin',
    dataAlreadyMinted: 'dataAlreadyMinted',
    connectAccountIncorrect: 'connectAccountIncorrect',
    userDenySignature: 'userDenySignature',
    missingInfoParam: 'missingInfoParam',
    somethingWentWrong: 'somethingWentWrong',
    incorrectChain: 'incorrectChain',
    metadataMissing: 'metadataMissing',
    rawDataMissing: 'rawDataMissing',
    nonceProblem: 'nonceProblem',
    walletNotConnected: 'walletNotConnected',
    sendMintInfoFail: 'sendMintInfoFail',
}
