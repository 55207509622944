import { createTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

const defaultTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1200,
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    minWidth: 100,
                    '&.Mui-disabled': {
                        borderWidth: '2px',
                    },
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: '#52C3BB',
                    },
                },
                containedSecondary: {
                    '&:hover': {
                        backgroundColor: '#FFCD52',
                    },
                },
                outlinedPrimary: {
                    border: '2px solid #00A89B',
                    '&:hover': {
                        border: '2px solid #00A89B',
                        backgroundColor: '#00A89B20',
                    },
                },
                outlinedSecondary: {
                    border: '2px solid #FFB600',
                    '&:hover': {
                        border: '2px solid #FFB600',
                        backgroundColor: '#FFB60020',
                    },
                },
                text: {
                    borderRadius: 5,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    padding: '5px',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: '#96D200',
                    },
                    '&.Mui-completed': {
                        color: '#96D200',
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#007aff',
                    textDecorationColor: '#007aff',
                    cursor: 'pointer',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '8px',
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#00A89B',
            light: '#96D200',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#FFB600',
            contrastText: '#FFF',
        },
        info: {
            main: '#007AFF',
            background: '#EFF7FE',
        },
        error: {
            main: '#DC2C00',
            background: '#DC2C0019',
        },
        // TODO confirm status color
        success: {
            main: '#07e191',
        },
        warning: {
            main: '#ff9800',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
        action: {
            hover: '#F5FCFB',
        },
        grey: {
            light: grey[300],
        },
        mint: {
            background: '#333333',
            text: '#FABE28',
        },
    },
    shape: {
        borderRadius: 5,
    },
    shadows: [
        '0px 1px 3px 0px #00000040',
        '0px 3px 2px 0px #00000040',
        '0px 2px 5px 0px #00000040',
        '0px 3px 15px -2px #00000040',
        '0px 0px 30px -15px #00000040',
        'none',
        'none',
        'none',
        '0px 2px 5px 0px #00000030', // this is for showing menu paper shadow
    ],
    typography: {
        htmlFontSize: 16,
        fontSize: 14,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2rem',
            fontWeight: 600,
            letterSpacing: '0.08em',
            lineHeight: 1.5,
        },
        h2: {
            fontSize: '1.8rem',
            fontWeight: 600,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 500,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        h4: {
            fontSize: '1.3rem',
            fontWeight: 500,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        h5: {
            fontSize: '1.1rem',
            fontWeight: 500,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 400,
            letterSpacing: '0.03em',
            lineHeight: 1.8,
        },
        body1: {
            fontSize: '0.9rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '0.8rem',
            fontWeight: 400,
            letterSpacing: '0.03em',
            lineHeight: 1.5,
        },
        button: {
            fontSize: '0.9rem',
            fontWeight: 500,
            letterSpacing: '0.03em',
        },
    },
    mixins: {
        toolbar: {
            minHeight: 56,
            ['@media (min-width:0px)']: {
                minHeight: 56,
            },
            ['@media (min-width:600px)']: {
                minHeight: 56,
            },
        },
    },
})

defaultTheme.shadows[24] = defaultTheme.shadows[8]

export const getMuiTheme = () => {
    return defaultTheme
}
