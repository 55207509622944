import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import { useMediaQuery, Paper } from '@mui/material'

const RwdContainer = ({ children }) => {
    const isLaptopScreen = useMediaQuery((theme) => theme.breakpoints.up('laptop'))
    return (
        <>
            {isLaptopScreen ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 4 }}>
                    <Paper
                        elevation={3}
                        sx={{
                            width: 540,
                            minHeight: 640,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            borderRadius: '8px',
                            overflow: 'hidden',
                        }}
                    >
                        {children}
                    </Paper>
                </Box>
            ) : (
                <>{children}</>
            )}
        </>
    )
}

RwdContainer.propTypes = {
    children: PropTypes.element.isRequired,
}

export default RwdContainer
