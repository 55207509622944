const mintStageConstant = {
    confirmMintInfo: 'confirmMintInfo',
    mintSuccess: 'mintSuccess',
    alertMessage: 'alertMessage',
}

const attestationStageConstant = {
    attestation: 'attestation',
    success: 'success',
    error: 'error',
}

const connectStageConstant = {
    connectAccount: 'connectAccount',
    error: 'error',
}

export { mintStageConstant, attestationStageConstant, connectStageConstant }
