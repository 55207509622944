import { SET_WC_CONNECTED, SET_WC_ACCOUNT_INFO, SET_CONNECTOR, WC_DISCONNECT } from '../actionTypes'

export const setConnected = (isConnected) => ({
    type: SET_WC_CONNECTED,
    payload: { connected: isConnected },
})

export const setAccountInfo = ({ chainId, address, accounts }) => ({
    type: SET_WC_ACCOUNT_INFO,
    payload: { chainId, address, accounts },
})

export const setConnector = (connector) => ({
    type: SET_CONNECTOR,
    payload: { connector },
})

export const disconnect = () => ({
    type: WC_DISCONNECT,
})

export const disconnectWallet = (connector) => {
    if (connector) {
        try {
            connector.killSession().catch((error) => {
                console.log('connector killSession fail', error)
            })
        } catch (error) {
            console.log('connector killSession fail', error)
        }
    }
    return (dispatch) => {
        dispatch(disconnect())
    }
}
