import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Stack, Box, Typography } from '@mui/material'
import { ReactComponent as MintIcon } from '../../../image/ic_white_mint.svg'

const PendingForMintMessage = () => {
    const { t } = useTranslation()
    const [countDown, setCountDown] = useState(3)

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((previousValue) => {
                if (previousValue <= 0) {
                    clearInterval(interval)
                    return 0
                }
                return previousValue - 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }, [])
    return (
        <Stack p={2} spacing={1} sx={{ backgroundColor: 'mint.background' }}>
            <Box display="flex" alignItems="center" gap={1}>
                <MintIcon />
                <Typography variant="h4" color="common.white">
                    {`${t('Minting')}...`}
                </Typography>
            </Box>
            <Typography variant="body1" color="mint.text">
                {t('pendingMintingHint1')}
            </Typography>
            <Typography variant="body1" color="mint.text">
                {i18next.t('pendingMintingHint2', { countDown })}
            </Typography>
        </Stack>
    )
}

export default PendingForMintMessage
