import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Box, Button, Modal, Typography } from '@mui/material'
import { ReactComponent as WarningIcon } from '../../image/ic_warning.svg'
import { ReactComponent as CheckIcon } from '../../image/ic_green_check.svg'
import LoadingIcon from '../../image/loading.gif'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 328,
    minHeight: 60,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    pt: '24px',
    pb: '8px',
}

export const modalType = {
    success: 'success',
    warn: 'warn',
    loading: 'loading',
    plainTextInfo: 'plainTextInfo',
}
const HintModal = ({
    type,
    open,
    onClose,
    title,
    description,
    showButton,
    buttonText,
    buttonAction,
    buttonText2,
    buttonAction2,
}) => {
    const { t } = useTranslation()
    const hasDescriptionString = description && description !== ''
    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Box
                    display="flex"
                    alignItems={hasDescriptionString ? 'flex-start' : 'center'}
                    mb={1}
                    sx={{ pl: 1, pr: 1, mb: showButton ? 1 : 2 }}
                >
                    {type !== modalType.plainTextInfo && (
                        <Box sx={{ ml: 1 }}>
                            {type === modalType.loading && <img src={LoadingIcon} height={20} />}
                            {type === modalType.success && <CheckIcon />}
                            {type !== modalType.loading && type !== modalType.success && <WarningIcon />}
                        </Box>
                    )}
                    <Box sx={{ ml: 2, mr: 1 }}>
                        {title && title !== '' && (
                            <Box sx={{ mb: hasDescriptionString ? 1 : 0 }}>
                                <Typography
                                    variant="h5"
                                    color="textSecondary"
                                    sx={{ alignSelf: 'center', wordBreak: 'break-word' }}
                                >
                                    {t(title)}
                                </Typography>
                            </Box>
                        )}
                        {hasDescriptionString && (
                            <Typography
                                variant="body1"
                                color="textSecondary"
                                sx={{ alignSelf: 'center', wordBreak: 'break-word' }}
                            >
                                {t(description)}
                            </Typography>
                        )}
                    </Box>
                </Box>
                {showButton && (
                    <Box width="100%" display="flex">
                        <Button
                            color="secondary"
                            size="small"
                            sx={{ display: 'block', marginLeft: 'auto', marginBottom: '10px', mr: 1, minWidth: 60 }}
                            onClick={buttonAction || onClose}
                        >
                            {buttonText ? t(buttonText) : t('Close')}
                        </Button>
                        {buttonText2 && buttonText2 !== '' && (
                            <Button
                                color="secondary"
                                size="small"
                                sx={{ display: 'block', ml: 1, mr: 1, marginBottom: '10px', minWidth: 60 }}
                                onClick={buttonAction2}
                            >
                                {t(buttonText2)}
                            </Button>
                        )}
                    </Box>
                )}
            </Box>
        </Modal>
    )
}

HintModal.propTypes = {
    type: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    showButton: PropTypes.bool.isRequired,
    buttonText: PropTypes.string,
    buttonAction: PropTypes.func,
    buttonText2: PropTypes.string,
    buttonAction2: PropTypes.func,
}

export default HintModal
