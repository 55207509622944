import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { styled, Box, Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { isMobileDevice } from '../../util/mobileUtil'

const FooterContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0.5, 0),
    width: '100%',
    minHeight: 50,
    borderTop: `1px solid ${theme.palette.grey.light}`,
    display: 'flex',
    backgroundColor: theme.palette.common.white,
}))

const FooterButton = ({ onConfirmClick, buttonText, disabled, loading, showRetry, onRetryClick }) => {
    const { t } = useTranslation()
    const isMobile = isMobileDevice()

    return (
        <FooterContainer id="footer">
            <Box mx={3} display="flex" justifyContent="space-around" alignItems="center" flexGrow={1}>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    fullWidth
                    disabled={disabled}
                    loading={loading}
                    onClick={onConfirmClick}
                    sx={{ maxWidth: 328 }}
                >
                    {t(buttonText)}
                </LoadingButton>
            </Box>
            {isMobile && showRetry && (
                <Box display="flex" justifyContent="space-around" alignItems="center" flexGrow={1}>
                    <Button variant="outlined" color="secondary" fullWidth sx={{ mr: 2 }} onClick={onRetryClick}>
                        {t('Retry')}
                    </Button>
                </Box>
            )}
        </FooterContainer>
    )
}

FooterButton.propTypes = {
    onConfirmClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    showRetry: PropTypes.bool,
    onRetryClick: PropTypes.func,
}

export default FooterButton
