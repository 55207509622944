import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import Cookies from 'universal-cookie'
import zhTW from './zh-TW.json'
import en from './en.json'
import zh from './zh.json'
import ja from './ja.json'
import fr from './fr.json'
import de from './de.json'
import es from './es.json'
import it from './it.json'
import ru from './ru.json'

const cookies = new Cookies()

// set language, default to be windowLang
const windowLang = window.navigator.language
const indexOfDash = windowLang.indexOf('-')
const baseLang = windowLang.includes('zh')
    ? windowLang
    : indexOfDash > 0
    ? windowLang.slice(0, indexOfDash)
    : windowLang
var lng = baseLang

const cookieStoreLang = cookies.get('language', { path: '/' })
if (cookieStoreLang) {
    // if cookie lang exist, use lang set in cookie
    lng = cookieStoreLang
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: en,
            },
            'zh-TW': {
                translations: zhTW,
            },
            'zh-HK': {
                translations: zhTW,
            },
            'zh-CN': {
                translations: zh,
            },
            zh: {
                translations: zh,
            },
            ja: {
                translations: ja,
            },
            fr: {
                translations: fr,
            },
            de: {
                translations: de,
            },
            es: {
                translations: es,
            },
            it: {
                translations: it,
            },
            ru: {
                translations: ru,
            },
        },
        lng: lng,
        fallbackLng: 'en',
        debug: true,
        returnObjects: true,
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
