import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'

import { Box, Stepper, Step, StepLabel, StepContent, Button, Typography, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { LoadingButton } from '@mui/lab'

import KdanLogo from '../../../image/ic_kdanlogo.svg'
import WalletConnectionImg from '../../../image/img_itm_connect_wallet.svg'
import BlockchainCoinImg from '../../../image/img_blockchain_coin.svg'
import MetaMaskTextImg from '../../../image/img_metamask_textmark.svg'
import { isEmptyString, validateEmail } from '../../../util/stringUtil'
import { createMetaMaskProvider, createConnector, accountInfo, disconnect } from '../../../util/walletUtil'
import { checkRegister, registerOrLogin } from '../../../util/accountUtil'
import walletType from '../../../constant/WalletTypeConstant'
import errorConstant from './constant/ConnectErrorConstant'
import { setActiveStep, setIsRegisteredBefore } from '../../../redux/actions/ConnectStepperAction'
import { isMobileDevice } from '../../../util/mobileUtil'
import { isMetaMaskInstall } from '../../../util/metaMaskUtil'
import Cookies from 'universal-cookie'

export const KdanId = ({ id }) => {
    const { t } = useTranslation()

    return (
        <Box display="flex" alignItems="center">
            <img src={KdanLogo} alt="kdan_logo" />
            <Box ml={1}>
                <Typography variant="body2">{t('yourKdanIdTitle')}</Typography>
                <Typography variant="body2" color="primary">
                    {id}
                </Typography>
            </Box>
        </Box>
    )
}

KdanId.propTypes = {
    id: PropTypes.string.isRequired,
}

const EnterEmailStep = ({ isKdanId, email, setEmail, handleNext }) => {
    const [emailValid, setEmailValid] = useState(false)
    const { t } = useTranslation()

    const renderHelperText = (email) => {
        if (email === '') {
            return t('emailRequired')
        }

        if (validateEmail(email)) {
            return ''
        } else {
            return t('emailFormatInvalid')
        }
    }

    useEffect(() => {
        setEmailValid(validateEmail(email))
    }, [email])

    return (
        <React.Fragment>
            <StepLabel>{isKdanId ? t('enterKdanID') : t('setupWithEmail')}</StepLabel>
            <StepContent sx={{ mt: 1 }}>
                <Box>
                    {isKdanId ? (
                        <>
                            <KdanId id={email} />
                        </>
                    ) : (
                        <>
                            <Typography variant="body2" color="textPrimary">
                                {t('enterYourEmailTitle')}
                            </Typography>
                            <TextField
                                id="sign-up-card-email"
                                name="email"
                                size="small"
                                color="secondary"
                                placeholder={t('enterEmailLabel')}
                                variant="outlined"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={!emailValid}
                                helperText={renderHelperText(email)}
                                sx={{ mt: 1 }}
                            />
                        </>
                    )}
                    <Typography variant="caption" color="textSecondary">
                        {t('setupAgreementHint')}
                    </Typography>
                    <Stack mt={1} spacing={1} direction="row" alignItems="center">
                        <Typography
                            variant="caption"
                            color="textPrimary"
                            component="a"
                            href="https://itrustmachines.com/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('Privacy')}
                        </Typography>
                        <Typography variant="caption" color="textPrimary">
                            |
                        </Typography>
                        <Typography
                            variant="caption"
                            color="textPrimary"
                            component="a"
                            href="https://bns.itrustmachines.com/terms-of-use"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t('termsOfUse')}
                        </Typography>
                    </Stack>
                    <Box sx={{ mt: 1, mb: 2 }}>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                            disabled={!emailValid}
                        >
                            {t('NEXT')}
                        </Button>
                    </Box>
                </Box>
            </StepContent>
        </React.Fragment>
    )
}

EnterEmailStep.propTypes = {
    isKdanId: PropTypes.bool,
    email: PropTypes.string,
    setEmail: PropTypes.func,
    handleNext: PropTypes.func.isRequired,
}

const ConnectWalletStep = ({ handleConnect, loading }) => {
    const { t } = useTranslation()
    const isMobileView = isMobileDevice()

    return (
        <React.Fragment>
            <StepLabel>{t('connectWalletStepperLabel')}</StepLabel>
            <StepContent sx={{ mt: 1 }}>
                {isMobileView ? (
                    <img src={WalletConnectionImg} alt="img_itm_connect_wallet" />
                ) : (
                    <img src={MetaMaskTextImg} alt="img_metamask_textmark" />
                )}
                <Box mt={1}>
                    {isMobileView ? (
                        <Typography variant="body1" color="textSecondary">
                            {t('connectToMetaMaskInstruction')}
                            <br />
                            {t('powerByWalletConnectHint')}
                        </Typography>
                    ) : (
                        <Typography variant="body1" color="textSecondary">
                            {t('connectToMetaMaskInstruction')}
                        </Typography>
                    )}
                </Box>
                <Box mt={1} mb={2}>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={loading}
                        onClick={handleConnect}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        {t('CONNECT')}
                    </LoadingButton>
                </Box>
            </StepContent>
        </React.Fragment>
    )
}

ConnectWalletStep.propTypes = {
    handleConnect: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

const SignStep = ({ signData, handleSign, loading, showRetry, onSignProgress }) => {
    const { t } = useTranslation()
    const isMobile = isMobileDevice()

    const renderMessage = (message) => {
        const lines = message.split('\n')
        return lines.map((line, index) => {
            return (
                <React.Fragment key={`line-${index}`}>
                    {line === '' ? (
                        <br />
                    ) : (
                        <Typography variant="body1" color="textSecondary" sx={{ wordBreak: 'break-word' }}>
                            {line}
                        </Typography>
                    )}
                </React.Fragment>
            )
        })
    }

    return (
        <React.Fragment>
            <StepLabel>{t('connectITMServiceLabel')}</StepLabel>
            <StepContent sx={{ mt: 1 }}>
                {renderMessage(signData)}
                <Box mt={1} mb={2}>
                    <LoadingButton
                        variant="contained"
                        size="small"
                        loading={loading}
                        onClick={handleSign}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        {t('NEXT')}
                    </LoadingButton>
                    {isMobile && showRetry && (
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={onSignProgress}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            {t('Retry')}
                        </Button>
                    )}
                </Box>
            </StepContent>
        </React.Fragment>
    )
}

SignStep.propTypes = {
    isRegistered: PropTypes.bool.isRequired,
    signData: PropTypes.string.isRequired,
    handleSign: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    showRetry: PropTypes.bool.isRequired,
    onSignProgress: PropTypes.func.isRequired,
}

const FinishStep = () => {
    const { t } = useTranslation()

    return (
        <React.Fragment>
            <StepLabel>{t('finishStepperLabel')}</StepLabel>
            <StepContent sx={{ mt: 1 }}>
                <Box display="flex" alignItems="center">
                    <img src={BlockchainCoinImg} alt="blockchain-coin-img" />
                    <Typography variant="h6" sx={{ ml: 2, alignSelf: 'center' }}>
                        {t('walletConnectedSuccessfullyTitle')}
                    </Typography>
                </Box>
            </StepContent>
        </React.Fragment>
    )
}

const ConnectStepper = ({ onError, onMetaMaskNotInstall, ...otherProps }) => {
    const { t } = useTranslation()

    let [searchParams] = useSearchParams()
    const connectionToken = searchParams.get('connectionToken')
    const emailFromParams = searchParams.get('email')
    const isKdanId = emailFromParams && emailFromParams !== ''
    const [email, setEmail] = useState(isKdanId ? emailFromParams : '')
    const [signData, setSignData] = useState('')
    const [countDown, setCountDown] = useState(3)
    const [step1Loading, setStep1Loading] = useState(false)
    const [step3Loading, setStep3Loading] = useState(false)
    const { isRegisteredBefore, setIsRegisteredBefore, activeStep, setActiveStep } = otherProps

    useEffect(() => {
        const connectionToken = searchParams.get('connectionToken')
        if (isEmptyString(connectionToken)) {
            console.log('no connectionToken')
            onError(errorConstant.connectionTokenExpired)
        }
    }, [])

    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }

    const handleFinish = () => {
        handleNext()
        const interval = setInterval(() => {
            setCountDown((previousValue) => {
                if (previousValue <= 0) {
                    clearInterval(interval)
                    return 0
                }
                return previousValue - 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }

    const skipNextStep = () => {
        setActiveStep(activeStep + 2)
    }

    const connectMetaMaskExtension = async () => {
        return createMetaMaskProvider().catch((error) => {
            console.log('connectMetaMaskExtension Error', error)
            if (error.code === 4001) {
                onError(errorConstant.userRejectConnection)
            } else if (error.code === -32002) {
                onError(errorConstant.resourceUnavailable)
            } else {
                onError(errorConstant.somethingWentWrong)
            }
        })
    }

    const checkIsRegister = async (accountInfo) => {
        if (accountInfo && accountInfo.address !== '') {
            setStep1Loading(true)
            const checkRegisterRes = await checkRegister(accountInfo.address)
            const isRegistered = checkRegisterRes.data ? checkRegisterRes.data : false
            setIsRegisteredBefore(isRegistered)
            if (isRegistered) {
                // skip email setup step
                skipNextStep()
            } else {
                handleNext()
            }
        } else {
            onError(errorConstant.userRejectConnection)
        }
    }

    const connectWallet = async () => {
        const isMobile = isMobileDevice()
        let accountInfo

        if (isMobile) {
            accountInfo = await createConnector().catch((error) => {
                if (error.code === 4001) {
                    onError(errorConstant.userRejectConnection)
                } else {
                    onError(errorConstant.somethingWentWrong)
                }
            })
            checkIsRegister(accountInfo)
        } else {
            setStep1Loading(true)
            if (isMetaMaskInstall()) {
                accountInfo = await connectMetaMaskExtension()
                checkIsRegister(accountInfo)
            } else {
                onMetaMaskNotInstall()
            }
        }
    }

    const onSignProgress = () => {
        const { address } = accountInfo
        const cookies = new Cookies()
        const cookieWalletType = cookies.get('select-wallet-type', { path: '/' })
        const selectWalletType = cookieWalletType || walletType.walletConnect
        registerOrLogin({
            checkRegisterResult: isRegisteredBefore,
            connectionToken,
            email,
            address,
            selectWalletType,
            toSignMessage: signData,
        })
            .then(() => {
                handleFinish()
            })
            .catch((error) => {
                onError(error)
            })
    }

    const handleSign = () => {
        setStep3Loading(true)
        onSignProgress()
        const interval = setInterval(() => {
            setRetryCountDown((previousValue) => {
                if (previousValue <= 0) {
                    clearInterval(interval)
                    return 0
                }
                return previousValue - 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }

    useEffect(() => {
        const isMobile = isMobileDevice()
        // 第一步驟強制斷連WalletConnect避免產生問題
        if (activeStep === 0 && isMobile) {
            disconnect()
        }

        if (activeStep === 2) {
            const timestamp = Date.now()
            const { address } = accountInfo
            const toSignMessage = `${t('signMessageLine1')}\n${t('signMessageLine2')}\n\n${t('walletAddress')}${t(
                'colon',
            )} ${address}${
                isRegisteredBefore ? `` : `\n${t('registerEmail')}${t('colon')} ${email}`
            }\n\nValidation Field:\naddress:${address}, timestamp:${timestamp}`
            setSignData(toSignMessage)
        }
    }, [activeStep, t])

    // sign progress retry button
    const [showRetry, setShowRetry] = useState(false)
    const [retryCountDown, setRetryCountDown] = useState(3)

    useEffect(() => {
        if (retryCountDown <= 0) {
            setShowRetry(true)
        }
    }, [retryCountDown])

    return (
        <Box sx={{ maxWidth: 400, p: 3 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                <Step>
                    <ConnectWalletStep handleConnect={connectWallet} loading={step1Loading} />
                </Step>
                <Step>
                    <EnterEmailStep isKdanId={isKdanId} email={email} setEmail={setEmail} handleNext={handleNext} />
                </Step>
                <Step>
                    <SignStep
                        isRegistered={isRegisteredBefore}
                        signData={signData}
                        handleSign={handleSign}
                        loading={step3Loading}
                        showRetry={showRetry}
                        onSignProgress={onSignProgress}
                    />
                </Step>
                <Step completed={activeStep >= 3} last>
                    <FinishStep />
                </Step>
                {activeStep >= 3 && (
                    <Box sx={{ ml: 2, pl: 2, pr: 1 }}>
                        <Typography variant="body1" color="textSecondary">
                            {t('connectWalletDoneHint')}
                            <br />
                            <br />
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {i18next.t('connectFinishAutoSendBackHint', { countDown })}
                        </Typography>
                    </Box>
                )}
            </Stepper>
        </Box>
    )
}

ConnectStepper.propTypes = {
    onError: PropTypes.func.isRequired,
    onMetaMaskNotInstall: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const connectStepperState = state.connectStepperReducer
    const activeStep = connectStepperState.activeStep
    const isRegisteredBefore = connectStepperState.isRegisteredBefore
    return { activeStep, isRegisteredBefore }
}

export default connect(mapStateToProps, { setActiveStep, setIsRegisteredBefore })(ConnectStepper)
