import React, { useState } from 'react'
import { Box } from '@mui/system'
import { styled } from '@mui/material'
import ConnectStepper from './ConnectStepper'
import RwdContainer from '../../common/RwdContainer'
import ErrorMessage from '../../common/ErrorMessage'
import { connectStageConstant as stageConstant } from '../../../constant/StageConstant'
import errorConstant from './constant/ConnectErrorConstant'
import HintModal, { modalType } from '../../common/HintModal'

const CustomBox = styled(Box)(({ theme }) => ({
    height: '100%',
    [theme.breakpoints.down('laptop')]: {
        backgroundColor: theme.palette.common.white,
    },
}))

const SignUpPage = () => {
    const [stage, setStage] = useState(stageConstant.connectAccount)
    const [error, setError] = useState({
        title: 'somethingWentWrong',
        description: 'tryAgainLaterHint',
        buttonText: 'Retry',
        hideButton: false,
        buttonAction: () => retry(),
    })
    const [metaMaskNotInstallModalOpen, setMetaMaskNotInstallModalOpen] = useState(false)

    const onError = (type) => {
        if (type === errorConstant.connectionTokenExpired) {
            setError({
                title: 'connectionFailTitle',
                description: 'sessionExpireGoBackToApp',
                hideButton: true,
            })
        } else if (type === errorConstant.userDenySignature) {
            setError({
                title: 'userRejectSignature',
                description: 'connectUnsignedAgreementDetail',
                hideButton: false,
            })
        } else if (type === errorConstant.signFail) {
            setError({
                title: 'signMessageFailTitle',
                description: ['signMessageFailDescription', 'signMessageFailHint'],
                hideButton: false,
            })
        } else if (type === errorConstant.accountDisabled) {
            setError({
                title: 'accountDisabledAlertTitle',
                description: ['thisAccountHasBeenDisabled', 'accountDisabledAlertHint'],
                hideButton: false,
                buttonText: 'tryWithAnotherAddress',
                buttonAction: () => reload(),
            })
        } else if (type === errorConstant.walletHasBeenDisconnected) {
            setError({
                title: 'walletNotConnectedTitle',
                description: 'checkWalletConnectionAndTryAgainHint',
                hideButton: false,
                buttonAction: () => reload(),
            })
        } else if (type === errorConstant.userRejectConnection) {
            setError({
                title: 'userRejectConnectionTitle',
                description: 'userShouldApproveConnectionHint',
                hideButton: false,
            })
        } else if (type === errorConstant.metamaskExtensionNotInstall) {
            setError({
                title: 'metamaskExtensionNotInstallTitle',
                description: 'metamaskExtensionNotInstallHint',
                hideButton: false,
                buttonAction: () => reload(),
            })
        } else if (type === errorConstant.resourceUnavailable) {
            setError({
                title: 'metamaskExtensionNotAvailableTitle',
                description: 'metamaskExtensionAlreadyProcessHint',
                hideButton: false,
            })
        } else {
            setError({
                title: 'somethingWentWrong',
                description: 'tryAgainLaterHint',
                hideButton: false,
                buttonAction: () => reload(),
            })
        }
        setStage(stageConstant.error)
    }

    const onMetaMaskNotInstall = () => {
        setMetaMaskNotInstallModalOpen(true)
    }

    const handleMetaMaskDownloadClick = () => {
        const url = `https://metamask.io/download/`
        window.open(url, '_blank')
    }

    const retry = () => {
        setStage(stageConstant.connectAccount)
    }

    const reload = () => {
        location.reload()
    }

    return (
        <CustomBox>
            {stage === stageConstant.connectAccount && (
                <RwdContainer>
                    <ConnectStepper onError={onError} onMetaMaskNotInstall={onMetaMaskNotInstall} />
                </RwdContainer>
            )}
            {stage === stageConstant.error && (
                <ErrorMessage
                    title={error.title}
                    description={error.description}
                    buttonText={error.buttonText}
                    hideButton={error.hideButton}
                    onClick={error.buttonAction || retry}
                />
            )}
            <HintModal
                type={modalType.plainTextInfo}
                open={metaMaskNotInstallModalOpen}
                description={`metaMaskNotInstallDescription`}
                showButton
                buttonText={`installMetaMaskButton`}
                buttonAction={handleMetaMaskDownloadClick}
                buttonText2={`Retry`}
                buttonAction2={reload}
            />
        </CustomBox>
    )
}

export default SignUpPage
