import axios from '../action/MintServerApi'

const fetchCryptoPrice = () => {
    return new Promise((resolve) => {
        axios
            .get(`/rest/crypto`)
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                console.log('fetchCryptoPrice() error:', error)
                resolve({ etherPrice: -1, polygonPrice: -1 })
            })
    })
}

const etherToUsd = async (ether) => {
    const cryptoPrice = await fetchCryptoPrice()
    const { etherPrice } = cryptoPrice
    return etherPrice < 0 ? etherPrice : (Number(ether) * etherPrice).toFixed(2)
}

const maticToUsd = async (matic) => {
    const cryptoPrice = await fetchCryptoPrice()
    const { polygonPrice } = cryptoPrice
    return polygonPrice < 0 ? polygonPrice : (Number(matic) * polygonPrice).toFixed(2)
}

const cryptoPricetoUsd = async (cryptoPrice, unit) => {
    let result = 0
    if (unit && unit.toLocaleLowerCase() === 'matic') {
        result = await maticToUsd(cryptoPrice)
    } else {
        result = await etherToUsd(cryptoPrice)
    }
    result = Number(result).toFixed(2)
    return result
}

export { cryptoPricetoUsd }
