import Web3 from 'web3'
import ChainId from './ChainId'

const infuraId = process.env.REACT_APP_INFURA_PROJECT_ID

export default {
    mumbai: {
        idInDecimal: ChainId.mumbai,
        idInHex: new Web3().utils.numberToHex(ChainId.mumbai),
        chainName: 'Mumbai',
        currencyName: 'Matic',
        currencySymbol: 'MATIC',
        blockExplorerUrl: 'https://mumbai.polygonscan.com',
        rpcUrl: `https://polygon-mumbai.infura.io/v3/${infuraId}`,
    },
    polygon: {
        idInDecimal: ChainId.polygon,
        idInHex: new Web3().utils.numberToHex(ChainId.polygon),
        chainName: 'Polygon',
        currencyName: 'Matic',
        currencySymbol: 'MATIC',
        blockExplorerUrl: 'https://polygonscan.com',
        rpcUrl: 'https://polygon-rpc.com',
    },
    ethereum: {
        idInDecimal: ChainId.mainnet,
        idInHex: new Web3().utils.numberToHex(ChainId.mainnet),
    },
    goerli: {
        idInDecimal: ChainId.goerli,
        idInHex: new Web3().utils.numberToHex(ChainId.goerli),
    },
}
