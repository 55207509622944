export default {
    MAINNET: 1,
    mainnet: 1,
    GOERLI: 5,
    goerli: 5,
    POLYGON: 137,
    polygon: 137,
    MUMBAI: 80001,
    mumbai: 80001,
}
