import React from 'react'
import { Box, AppBar, Toolbar, styled } from '@mui/material'
import itm_logo from '../../image/itm_logo.svg'
import LanguageDropdown from './language/LanguageDropdown'

const CustomAppBar = styled(AppBar)(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.action.hover}`,
    backgroundColor: 'white',
    boxShadow: theme.shadows[4],
    position: 'unset',
    top: 0,
    zIndex: theme.zIndex.appBar,
}))

const Navbar = () => {
    return (
        <CustomAppBar id="navbar">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box width={154} height={36}>
                    <img src={itm_logo} alt="ITM logo" width="100%" />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="flex-end" flexGrow={1}>
                    <LanguageDropdown />
                </Box>
            </Toolbar>
        </CustomAppBar>
    )
}

export default Navbar
