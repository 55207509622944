import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography, Box, IconButton, CircularProgress } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'
import { convertFixedEstimatedFee } from '../../../../util/numberUtil'

const WalletBalance = ({ walletBal, wholeEstimatedFee, currencyUnit, isRefreshBalance, fetchWalletBalance }) => {
    const { t } = useTranslation()
    const fixedEstimatedFee = convertFixedEstimatedFee(wholeEstimatedFee)
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" flexDirection="column">
                <Typography variant="caption" color="text.secondary">
                    {`${t('walletBalance')}: ${walletBal} ${currencyUnit || 'ETH'}`}
                </Typography>
                {walletBal < wholeEstimatedFee && (
                    <Typography variant="caption" color="error">
                        {`${t('insufficientBalance')}: ${fixedEstimatedFee} ${currencyUnit || 'ETH'}`}
                    </Typography>
                )}
            </Box>
            <IconButton size="small" onClick={fetchWalletBalance} disabled={isRefreshBalance}>
                {isRefreshBalance ? <CircularProgress size={18} sx={{ color: 'text.hint' }} /> : <RefreshIcon />}
            </IconButton>
        </Box>
    )
}

WalletBalance.propTypes = {
    walletBal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    wholeEstimatedFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currencyUnit: PropTypes.string.isRequired,
    isRefreshBalance: PropTypes.bool.isRequired,
    fetchWalletBalance: PropTypes.func.isRequired,
}

export default WalletBalance
