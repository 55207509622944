const isMobileDevice = () => {
    var mobileDevices = [/Android/, /webOS/, /iPhone/, /iPad/, /iPod/, /BlackBerry/, /Windows Phone/]
    var isMobileDevice = false
    for (var i = 0; i < mobileDevices.length; i++) {
        if (navigator.userAgent.match(mobileDevices[i])) {
            isMobileDevice = true
        }
    }
    return isMobileDevice
}

export { isMobileDevice }
