export default {
    accountDisabled: 'accountDisabled',
    walletHasBeenDisconnected: 'walletHasBeenDisconnected',
    somethingWentWrong: 'somethingWentWrong',
    connectionTokenExpired: 'connectionTokenExpired',
    userDenySignature: 'userDenySignature',
    signFail: 'signFail',
    metamaskExtensionNotInstall: 'metamaskExtensionNotInstall',
    userRejectConnection: 'userRejectConnection',
    resourceUnavailable: 'resourceUnavailable',
}
