import React, { useState, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Cookies from 'universal-cookie'
import { styled, Box, Typography } from '@mui/material'
import FooterButton from '../../common/FooterButton'
import HintModal, { modalType } from '../../common/HintModal'
import OriginalMessage from './OriginalMessage'
import { extractProjectKey, isEmptyString } from '../../../util/stringUtil'
import { doAttestation, getLedgerInputInfo } from '../../../util/attestationUtil'
import { connectMetaMaskAccount } from '../../../util/metaMaskUtil'
import { web3, createWeb3AndContractService, accountInfo } from '../../../util/walletUtil'
import { UserInfoContext } from '../../../App'
import errorConstant from './AttestationErrorConstant'
import walletType from '../../../constant/WalletTypeConstant'
import { getContractAddress } from '../../../util/envUtil'

const CustomContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('laptop')]: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
    },
}))

const FormatMessage = ({ walletAddress, projectKey, projectName, description, timestamp }) => {
    const { t } = useTranslation()

    const convertTimestampToDateTime = (timestamp) => {
        return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
    }

    return (
        <>
            <Box id="message-content" mt={1}>
                <Typography id="action-explain-text" variant="body1" color="textSecondary">
                    {t('signatureRequestedMessage')}
                </Typography>
                <Typography id="signature-explain-text" variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                    {t('signatureIsRequestedForCreatingBNS')}
                </Typography>
                <Box mt={2} sx={{ wordBreak: 'break-all' }}>
                    <Typography id="wallet-address-text" variant="body1" color="textSecondary">
                        {`* ${t('walletAddress')}: ${walletAddress}`}
                    </Typography>
                    <Typography id="project-creator-text" variant="body1" color="textSecondary">
                        {`* ${t('Source')}${t('colon')} Animation Desk`}
                    </Typography>
                    <Typography id="project-key-text" variant="body1" color="textSecondary">
                        {`* ${t('projectKey')}${t('colon')} ${projectKey}`}
                    </Typography>
                    <Typography id="project-name-text" variant="body1" color="textSecondary">
                        {`* ${t('projectName')}${t('colon')} ${projectName}`}
                    </Typography>
                    <Typography id="bns-description-text" variant="body1" color="textSecondary">
                        {`* ${t('BNSDescription')}${t('colon')} ${description}`}
                    </Typography>
                    <Typography id="time-text" variant="body1" color="textSecondary">
                        {`* ${t('dateTime')}${t('colon')} ${convertTimestampToDateTime(timestamp)}`}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

FormatMessage.propTypes = {
    walletAddress: PropTypes.string.isRequired,
    projectKey: PropTypes.string.isRequired,
    projectName: PropTypes.string,
    description: PropTypes.string,
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

const AttestationContent = ({ onError }) => {
    const { t } = useTranslation()
    const cookies = new Cookies()
    const [userInfo] = useContext(UserInfoContext)
    const [searchParams] = useSearchParams()
    const chosenEnvInParam = isEmptyString(searchParams.get('chain')) ? 'ETHEREUM' : searchParams.get('chain')
    const contractAddress = getContractAddress(chosenEnvInParam)
    const [cmdObject, setCmdObject] = useState({ projectKey: '' })
    const [ledgerInputInfo, setLedgerInputInfo] = useState({
        callerAddress: '',
        timestamp: 0,
        cmd: '',
        indexValue: '',
        metadata: '',
        clearanceOrder: 0,
        toSignMessage: '',
    })
    const [loadingBtn, setLoadingBtn] = useState(true)
    const [isAttestationDone, setIsAttestationDone] = useState(false)

    useEffect(() => {
        console.log('AttestionPage userInfo=', userInfo)
        const isLogin = userInfo && userInfo.address !== '' && userInfo.email !== ''
        if (!isLogin) {
            console.log('user not login yet')
            onError(errorConstant.userNotLogin)
        } else {
            createWeb3Service()
            let cmdParam = searchParams.get('cmd')
            console.log(cmdParam)
            if (!cmdParam) {
                onError(errorConstant.failedToGetCmdParam)
            }
            if (cmdParam) {
                var cmdObject = null
                try {
                    cmdObject = JSON.parse(cmdParam)
                } catch (error) {
                    console.error('parse cmd fail')
                    onError(errorConstant.failedToGetCmdParam)
                }
                console.log('cmdObject=', cmdObject)
                setCmdObject(cmdObject)
                var projectKeyInParam = cmdObject['projectKey']
                var fileHashInParam = cmdObject['fileHash']
                var cmdAddress = extractProjectKey(projectKeyInParam)['address']
            }
            let apiTokenInParam = searchParams.get('apiToken')
            if (!apiTokenInParam) {
                onError(errorConstant.apiTokenInvalid)
            } else if (!projectKeyInParam || !fileHashInParam) {
                onError(errorConstant.failedToGetCmdParam)
            } else if (cmdAddress !== userInfo.address) {
                onError(errorConstant.addressNotMatch)
            } else {
                getLedgerInputInfo(userInfo.address, cmdParam, apiTokenInParam)
                    .then((result) => {
                        setLedgerInputInfo(result)
                        setLoadingBtn(false)
                    })
                    .catch((error) => {
                        let errorRes = error.response.data
                        if (errorRes?.message.includes('invalid api token')) {
                            onError(errorConstant.apiTokenInvalid)
                        } else {
                            onError(errorConstant.somethingWentWrong)
                        }
                    })
            }
        }
    }, [searchParams])

    const createWeb3Service = async () => {
        const selectWalletType = cookies.get('select-wallet-type', { path: '/' })
        await createWeb3AndContractService(selectWalletType, contractAddress)
        if (accountInfo.address !== userInfo.address) {
            onError(errorConstant.connectAccountIncorrect)
        }
    }

    const onLedgerInputSuccess = () => {
        setLoadingBtn(false)
        setIsAttestationDone(true)
    }

    const isWalletConnected = async () => {
        if (cookies.get('select-wallet-type', { path: '/' }) === walletType.walletConnect) {
            return web3.currentProvider.connected
        } else {
            const metaMaskAccountInfo = await connectMetaMaskAccount()
            if (metaMaskAccountInfo.address && metaMaskAccountInfo.address !== '') {
                return true
            }
        }
        return false
    }

    const mainAction = async () => {
        doAttestation(ledgerInputInfo, userInfo.address, searchParams.get('apiToken'))
            .then(() => {
                onLedgerInputSuccess()
            })
            .catch((error) => {
                if (error.message?.includes('invalid cmd format, missing fileHash or projectKey')) {
                    onError(errorConstant.failedToGetCmdParam)
                } else if (error.message?.includes('User denied message signature')) {
                    onError(errorConstant.userDenySignature)
                } else if (error.message?.includes('Session currently disconnected')) {
                    onError(errorConstant.apiTokenInvalid)
                } else if (error.message?.includes('Request params invalid')) {
                    onError(errorConstant.signFail)
                } else if (error?.error.includes('TxCount over limit')) {
                    onError(errorConstant.txCountOverLimit)
                } else {
                    onError(errorConstant.somethingWentWrong)
                }
                setLoadingBtn(false)
            })
    }

    const [showRetry, setShowRetry] = useState(false)
    const [retryCountDown, setRetryCountDown] = useState(3)

    useEffect(() => {
        if (retryCountDown <= 0) {
            setShowRetry(true)
        }
    }, [retryCountDown])

    const onNextClick = async () => {
        if (await isWalletConnected()) {
            setLoadingBtn(true)
            mainAction()
            const interval = setInterval(() => {
                setRetryCountDown((previousValue) => {
                    if (previousValue <= 0) {
                        clearInterval(interval)
                        return 0
                    }
                    return previousValue - 1
                })
            }, 1000)
            return () => clearInterval(interval)
        } else {
            onError(errorConstant.walletNotConnected)
        }
    }

    return (
        <>
            <CustomContainer>
                <Box p={3}>
                    <Typography variant="h3" color="textPrimary">
                        {t('signatureRequested')}
                    </Typography>
                    <Box mt={2}>
                        <FormatMessage
                            walletAddress={userInfo.address}
                            projectKey={cmdObject.projectKey}
                            projectName={cmdObject.fileName}
                            description={cmdObject.description}
                            timestamp={cmdObject.timestamp}
                        />
                    </Box>
                    <Box mt={2}>
                        <OriginalMessage message={ledgerInputInfo.toSignMessage} />
                    </Box>
                </Box>
            </CustomContainer>
            {!isAttestationDone && (
                <FooterButton
                    buttonText={'CREATE'}
                    disabled={isEmptyString(ledgerInputInfo.toSignMessage)}
                    onConfirmClick={onNextClick}
                    loading={loadingBtn}
                    showRetry={showRetry}
                    onRetryClick={mainAction}
                />
            )}
            <HintModal
                type={modalType.success}
                open={isAttestationDone}
                title={'signatureSentAndTransmittingTitle'}
                showButton={false}
            />
        </>
    )
}

AttestationContent.propTypes = {
    onError: PropTypes.func.isRequired,
}

export default AttestationContent
