import { SET_WC_CONNECTED, SET_WC_ACCOUNT_INFO, SET_CONNECTOR, WC_DISCONNECT } from '../actionTypes'

const initialState = {
    connector: null,
    accountInfo: { chainId: 1, address: '', accounts: [] },
    connected: false,
}

const walletConnectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WC_ACCOUNT_INFO:
            return {
                ...state,
                accountInfo: {
                    chainId: action.payload.chainId,
                    address: action.payload.address,
                    accounts: action.payload.accounts,
                },
            }
        case SET_WC_CONNECTED:
            return {
                ...state,
                connected: action.payload.connected,
            }
        case SET_CONNECTOR:
            return {
                ...state,
                connector: action.payload.connector,
            }
        case WC_DISCONNECT:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export default walletConnectReducer
