import React, { useState } from 'react'
import errorConstant from './AttestationErrorConstant'
import { getLedgerInputInfo } from '../../../util/attestationUtil'
import { attestationStageConstant as stageConstant } from '../../../constant/StageConstant'
import ErrorMessage from '../../common/ErrorMessage'
import AttestationContent from './AttestationContent'
import RwdContainer from '../../common/RwdContainer'

const AttestationPage = () => {
    const [stage, setStage] = useState(stageConstant.attestation)
    const [error, setError] = useState({
        title: 'somethingWentWrong',
        description: 'tryAgainLaterHint',
        buttonText: 'Retry',
        hideButton: false,
        buttonAction: () => reload(),
    })

    const onError = (type) => {
        console.log('onError() type=', type)
        if (type === errorConstant.apiTokenInvalid) {
            setError({
                title: 'connectionFailTitle',
                description: 'sessionExpireGoBackToApp',
                hideButton: true,
            })
        } else if (type === errorConstant.userDenySignature) {
            setError({
                title: 'userRejectSignature',
                description: 'attestationUnsignedAgreementDetail',
                hideButton: false,
            })
        } else if (type === errorConstant.signFail) {
            setError({
                title: 'signMessageFailTitle',
                description: ['signMessageFailDescription', 'signMessageFailHint'],
                hideButton: false,
            })
        } else if (type === errorConstant.failedToGetCmdParam) {
            setError({
                title: 'failedToGetInformation',
                description: 'missingInfoGoBackToApp',
                hideButton: true,
            })
        } else if (type === errorConstant.walletNotConnected) {
            setError({
                title: 'walletNotConnectedTitle',
                description: 'checkWalletConnectionAndTryAgainHint',
                hideButton: false,
                buttonAction: () => reload(),
            })
        } else if (type === errorConstant.txCountOverLimit) {
            setError({
                title: 'txCountOverLimitTitle',
                description: 'txCountOverLimitHint',
                hideButton: false,
            })
        } else if (type === errorConstant.userNotLogin) {
            setError({
                title: 'userNotLoginTitle',
                description: 'goBackToAppAndLogin',
                hideButton: true,
            })
        } else if (type === errorConstant.addressNotMatch) {
            setError({
                title: 'projectAccessInvalidTitle',
                description: 'projectAccessInvalidHint',
                hideButton: true,
            })
        } else {
            setError({
                title: 'somethingWentWrong',
                description: 'tryAgainLaterHint',
                hideButton: false,
                buttonAction: () => reload(),
            })
        }
        setStage(stageConstant.error)
    }

    const retry = () => {
        getLedgerInputInfo()
        setStage(stageConstant.attestation)
    }

    const reload = () => {
        location.reload()
    }

    return (
        <>
            {stage === stageConstant.attestation && (
                <RwdContainer>
                    <AttestationContent onError={onError} />
                </RwdContainer>
            )}
            {stage === stageConstant.error && (
                <ErrorMessage
                    title={error.title}
                    description={error.description}
                    buttonText={error.buttonText}
                    hideButton={error.hideButton}
                    onClick={error.buttonAction || retry}
                />
            )}
        </>
    )
}

export default AttestationPage
