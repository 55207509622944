import { SET_ACTIVE_STEP, SET_IS_REGISTERED_BEFORE } from '../actionTypes'

const initialState = {
    activeStep: 0,
    isRegisteredBefore: false,
}

const connectStepperReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.payload,
            }
        case SET_IS_REGISTERED_BEFORE:
            return {
                ...state,
                isRegisteredBefore: action.payload,
            }
        default:
            return state
    }
}

export default connectStepperReducer
