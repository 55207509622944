import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Box } from '@mui/material'
import EstimatedCost from './EstimatedCost'
import WalletBalance from './WalletBalance'
import AddBalanceLink from './AddBalanceLink'
import { getCurrencyUnit } from '../../../../util/envUtil'

const EstimatedCostInfoAlert = ({ info, wholeEstimatedFee, walletBal, isRefreshBalance, fetchWalletBalance }) => {
    const currencyUnit = getCurrencyUnit(info.chain)

    return (
        <Box p={2} sx={{ backgroundColor: walletBal < wholeEstimatedFee ? 'error.background' : 'info.background' }}>
            <Stack spacing={2}>
                <EstimatedCost wholeEstimatedFee={wholeEstimatedFee} currencyUnit={currencyUnit} />
                <WalletBalance
                    walletBal={walletBal}
                    wholeEstimatedFee={wholeEstimatedFee}
                    currencyUnit={currencyUnit}
                    isRefreshBalance={isRefreshBalance}
                    fetchWalletBalance={fetchWalletBalance}
                />
                <AddBalanceLink />
            </Stack>
        </Box>
    )
}

EstimatedCostInfoAlert.propTypes = {
    info: PropTypes.object.isRequired,
    wholeEstimatedFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    walletBal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isRefreshBalance: PropTypes.bool.isRequired,
    fetchWalletBalance: PropTypes.func.isRequired,
}

export default EstimatedCostInfoAlert
