import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import { Stack, Typography, Button } from '@mui/material'
import { ReactComponent as WarningIcon } from '../../image/ic_warning.svg'
import RwdContainer from '../common/RwdContainer'
import { useTranslation } from 'react-i18next'

const ErrorMessage = ({ title, description, buttonText, onClick, hideButton }) => {
    const { t } = useTranslation()

    const renderDescription = () => {
        if (typeof description === 'string') {
            return (
                <Typography variant="h6" color="textPrimary">
                    {t(description)}
                </Typography>
            )
        } else {
            if (description.length > 0) {
                return description.map((text, index) => {
                    return (
                        <Typography variant="h6" color="textPrimary" key={`error-description-line${index}`}>
                            {t(text)}
                        </Typography>
                    )
                })
            } else {
                return (
                    <Typography variant="h6" color="textPrimary">
                        {i18next.t(description.text, { key: description.value })}
                    </Typography>
                )
            }
        }
    }

    return (
        <RwdContainer>
            <Stack mt={18} spacing={3} p={3} alignItems="center">
                <WarningIcon />
                <Stack spacing={1} alignItems="center">
                    <Typography variant="h4">{t(title)}</Typography>
                    {description && renderDescription()}
                </Stack>
                {!hideButton && (
                    <Button variant="outlined" color="secondary" onClick={onClick}>
                        {t(buttonText) || t('Retry')}
                    </Button>
                )}
            </Stack>
        </RwdContainer>
    )
}

ErrorMessage.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.objectOf(PropTypes.string),
    ]),
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    hideButton: PropTypes.bool.isRequired,
}

export default ErrorMessage
