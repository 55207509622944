const getCurrencyUnit = (chainName) => {
    let currencyUnit = 'ETH'
    if (['polygon', 'mumbai'].includes(chainName.toLocaleLowerCase())) {
        currencyUnit = 'Matic'
    }
    return currencyUnit
}

const getChainName = (userChosenEnv) => {
    let chainName = 'ETHEREUM'
    if (process.env.REACT_APP_CHAIN.toLocaleLowerCase() === 'testnet') {
        if (userChosenEnv.toLocaleLowerCase() === 'polygon') {
            chainName = 'MUMBAI'
        } else {
            chainName = 'GOERLI'
        }
    } else {
        if (userChosenEnv.toLocaleLowerCase() === 'polygon') {
            chainName = 'POLYGON'
        } else {
            chainName = 'ETHEREUM'
        }
    }

    return chainName
}

const getContractAddress = (userChosenEnv) => {
    const contractAddress =
        userChosenEnv.toLocaleLowerCase() === 'polygon'
            ? process.env.REACT_APP_POLYGON_CONTRACT_ADDRESS
            : process.env.REACT_APP_ETH_CONTRACT_ADDRESS

    return contractAddress
}

export { getCurrencyUnit, getChainName, getContractAddress }
