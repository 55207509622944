import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'
import { ReactComponent as EthereumIcon } from '../../../../image/ic_blockchain_ethereum.svg'
import { ReactComponent as PolygonIcon } from '../../../../image/img_blackchain_polygon.svg'
import { mintStageConstant } from '../../../../constant/StageConstant'

const BlockchainPreview = ({ info, status }) => {
    const { t } = useTranslation()
    const isConfirmStage = status === mintStageConstant.confirmMintInfo

    return (
        <>
            {['ethereum', 'ETHEREUM'].includes(info.chain) ? (
                <Box display="flex" alignItems="center" gap={1}>
                    <EthereumIcon width={24} height={24} />
                    <Typography variant={isConfirmStage ? 'h6' : 'body1'} color="text.secondary">
                        {`${t('Ethereum')} ${t(process.env.REACT_APP_CHAIN)}`}
                    </Typography>
                </Box>
            ) : (
                <Box display="flex" alignItems="center" gap={1}>
                    <PolygonIcon width={20} height={20} />
                    <Typography variant={isConfirmStage ? 'h6' : 'body1'} color="text.secondary">
                        {`${'Polygon'} ${t(process.env.REACT_APP_CHAIN)}`}
                    </Typography>
                </Box>
            )}
        </>
    )
}

BlockchainPreview.propTypes = {
    info: PropTypes.object.isRequired,
    status: PropTypes.string.isRequired,
}

export default BlockchainPreview
