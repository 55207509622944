import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Cookies from 'universal-cookie'
import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'
import { setConnector, disconnectWallet } from './redux/actions/WalletConnectAction'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { Box } from '@mui/material'

import { getMuiTheme } from './theme/Theme'
import './css/style.css'
import Navbar from './component/navbar/Navbar'
import MintPage from './component/page/MintPage/MintPage'
import ConnectAccountPage from './component/page/ConnectAccountPage/ConnectAccountPage'
import AttestationPage from './component/page/Attestation/AttestationPage'
import BuyCryptoInstruction from './component/page/BuyCryptoInstruction'

export const UserInfoContext = React.createContext({})

const App = (props) => {
    const theme = getMuiTheme()
    const cookies = new Cookies()
    const storeUserInfo = cookies.get('userInfo')
    const [userInfo, setuserInfo] = useState(
        storeUserInfo || {
            address: '',
            role: '',
            email: '',
            country: '',
            company: '',
            jobTitle: '',
            website: '',
            emailNotification: '',
        },
    )
    const [auth, setAuth] = useState(userInfo && userInfo.address !== '' && userInfo.email !== '')

    const { connector, setConnector } = props
    const createConnector = () => {
        const bridge = process.env.REACT_APP_BRIDGE_URL
        const newConnector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal })
        setConnector(newConnector)
    }

    useEffect(() => {
        console.log('userInfo: ', userInfo)
        if (userInfo && userInfo.address !== '') {
            setAuth(userInfo && userInfo.address !== '' && userInfo.email !== '')
            // cookie never expire
            cookies.set('userInfo', userInfo, { path: '/' })
            if (!connector) {
                createConnector()
            }
        } else if (auth) {
            setAuth(false)
            cookies.remove('userInfo', { path: '/' })
            if (connector) {
                disconnectWallet(connector)
            }
        }
    }, [userInfo])

    return (
        <UserInfoContext.Provider value={[userInfo, setuserInfo]}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <Box display="flex" flexDirection="column" width="100vw" height="100vh" overflow="auto">
                        <Navbar />
                        <Routes>
                            <Route path="sign-up" element={<ConnectAccountPage />} />
                            <Route path="login" element={<ConnectAccountPage />} />
                            <Route path="connect-wallet" element={<ConnectAccountPage />} />
                            <Route path="attestation" element={<AttestationPage />} />
                            <Route path="mint" element={<MintPage />} />
                            <Route path="buy-crypto" element={<BuyCryptoInstruction />} />
                        </Routes>
                    </Box>
                </ThemeProvider>
            </StyledEngineProvider>
        </UserInfoContext.Provider>
    )
}

App.propTypes = {
    connector: PropTypes.object,
    setConnector: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    const walletConnectState = state.walletConnectReducer
    const connector = walletConnectState.connector
    return { connector }
}

export default connect(mapStateToProps, { setConnector, disconnectWallet })(App)
