import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Stack, Typography, TextField, FormControl, FormHelperText, Box } from '@mui/material'

const QuantityInput = ({ info, handleChangeQuantity, handleBtnDisabled }) => {
    const { t } = useTranslation()
    const [error, setError] = useState({ isError: false, message: '' })

    useEffect(() => {
        let toSetError = { isError: false, message: '' }
        let toDisable = false

        const quantity = info.quantity

        if (!quantity || quantity < 1) {
            toSetError = {
                isError: true,
                message: t('quantityLimitHint'),
            }
            toDisable = true
        }
        setError(toSetError)
        handleBtnDisabled(toDisable)
    }, [info])

    return (
        <Stack spacing={0.5} px={2}>
            <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                {t('Supply')}
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
                <FormControl error variant="outlined">
                    <TextField
                        id="quantity-input"
                        required
                        type="number"
                        size="small"
                        value={info.quantity}
                        onChange={handleChangeQuantity}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        sx={{ maxWidth: 100 }}
                        error={error['isError']}
                    />
                    {error['isError'] && <FormHelperText>{error['message']}</FormHelperText>}
                </FormControl>
                <Typography variant="caption" color="text.hint">
                    {t('quantityDescription')}
                </Typography>
            </Box>
        </Stack>
    )
}

QuantityInput.propTypes = {
    info: PropTypes.object.isRequired,
    handleChangeQuantity: PropTypes.func.isRequired,
    handleBtnDisabled: PropTypes.func.isRequired,
}

export default QuantityInput
