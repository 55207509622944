// key is the i18n value
const constant = {
    en: 'English',
    'zh-TW': '繁體中文',
    zh: '简体中文',
    ja: '日本語',
    fr: 'Français',
    de: 'Deutsch',
    es: 'Español',
    ru: 'Русский',
    it: 'Italiano',
}

export default constant
