import { SET_ACTIVE_STEP, SET_IS_REGISTERED_BEFORE } from '../actionTypes'

export const setActiveStep = (activeStep) => ({
    type: SET_ACTIVE_STEP,
    payload: activeStep,
})

export const setIsRegisteredBefore = (isRegistered) => ({
    type: SET_IS_REGISTERED_BEFORE,
    payload: isRegistered,
})
