import Cookies from 'universal-cookie'
import axios from '../action/MintServerApi'
import { signData } from './signatureUtil'
import { connector, createConnector } from './walletUtil'
import walletType from '../constant/WalletTypeConstant'

const getCoSn = (address, apiToken) => {
    return new Promise((myResolve, myReject) => {
        axios
            .get(`/rest/clearance-order-and-sn?address=${address}&apiToken=${apiToken}`)
            .then((res) => {
                if (res && res.data?.clearanceOrder && !res.data?.indexValue.includes('null')) {
                    myResolve(res.data)
                } else {
                    myReject({ ...res, error: 'Invalid clearance order and sn' })
                }
            })
            .catch((error) => {
                console.log('get co sn error', error.message)
                myReject(error)
            })
    })
}

const getLedgerInputInfo = (address, cmdString, apiToken) => {
    return new Promise((myResolve, myReject) => {
        const lowerCaseAddress = address.toLocaleLowerCase()
        getCoSn(lowerCaseAddress, apiToken)
            .then((coSnRes) => {
                console.log('coSnRes=', coSnRes)
                let callerAddress = lowerCaseAddress
                let timestamp = Date.now()
                let metadata = ''
                //toSignMessage format: address + timestamp + cmd + indexValue + metadata + clearanceOrder
                const toSignMessage =
                    callerAddress + timestamp + cmdString + coSnRes.indexValue + metadata + coSnRes.clearanceOrder
                myResolve({
                    callerAddress: callerAddress,
                    timestamp: timestamp,
                    cmd: cmdString,
                    indexValue: coSnRes.indexValue,
                    metadata: metadata,
                    clearanceOrder: coSnRes.clearanceOrder,
                    toSignMessage,
                })
            })
            .catch((error) => {
                myReject(error)
            })
    })
}
const doAttestation = async (ledgerInputInfo, address, apiToken) => {
    const cookies = new Cookies()
    const selectWalletType = cookies.get('select-wallet-type', { path: '/' })
    let lowerCaseAddress = address.toLocaleLowerCase()
    if (selectWalletType === walletType.walletConnect && !connector) {
        const accountInfo = await createConnector()
        lowerCaseAddress = accountInfo.address.toLocaleLowerCase()
    }
    return new Promise((myResolve, myReject) => {
        signData({
            message: ledgerInputInfo.toSignMessage,
            address: lowerCaseAddress,
            connector: connector,
            type: selectWalletType,
        })
            .then((ledgerInputSignature) => {
                const requestBody = {
                    ...ledgerInputInfo,
                    sigClient: {
                        r: ledgerInputSignature.r,
                        s: ledgerInputSignature.s,
                        v: ledgerInputSignature.v,
                    },
                    apiToken: apiToken,
                    address: lowerCaseAddress,
                }
                console.log('onSignClick() requestBody=', requestBody)
                axios
                    .post('/rest/attestation', requestBody)
                    .then((res) => {
                        console.log('ledger input, res=', res)
                        myResolve(res.data)
                    })
                    .catch((error) => {
                        let errorRes = error.response.data
                        console.log('errorRes: ', errorRes)
                        myReject(errorRes)
                    })
            })
            .catch((error) => {
                console.log('error: ', error.message)
                myReject(error)
            })
    })
}
export { getLedgerInputInfo, doAttestation }
