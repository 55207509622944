import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { ExpandCircleDownOutlined } from '@mui/icons-material'

const OriginalMessage = ({ message }) => {
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(false)

    return (
        <>
            <Typography id="sign-message-title" variant="h5">
                {`${t('originalSignedMessage')}${t('colon')}`}
            </Typography>
            <Box mt={1}>
                {expanded ? (
                    <Box>
                        <Box mb={1}>
                            <Typography
                                id="message-content"
                                variant="body1"
                                color="textSecondary"
                                sx={{ wordBreak: 'break-all' }}
                            >
                                {message}
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <Box display="flex" alignItems="center">
                        <Box width="80%" mr={1}>
                            <Typography
                                id="message-content"
                                variant="body1"
                                color="textSecondary"
                                noWrap
                                sx={{ wordBreak: 'break-all' }}
                            >
                                {message}
                            </Typography>
                        </Box>
                        <IconButton onClick={() => setExpanded(true)}>
                            <ExpandCircleDownOutlined />
                        </IconButton>
                    </Box>
                )}
            </Box>
        </>
    )
}

OriginalMessage.propTypes = {
    message: PropTypes.string.isRequired,
}

export default OriginalMessage
