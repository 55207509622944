const isMetaMaskInstall = () => {
    if (window.ethereum !== undefined) {
        console.log('Metamask is installed')
        return true
    } else {
        console.log('Metamask not installed yet')
        return false
    }
}

const connectMetaMaskAccount = () => {
    return new Promise((myResolve, myReject) => {
        window.ethereum
            .request({ method: 'eth_requestAccounts' })
            .then((accounts) => {
                console.log('connectMetaMaskAccount() accounts=', accounts)
                window.ethereum.request({ method: 'eth_chainId' }).then((chainId) => {
                    myResolve({ address: accounts[0], chainId: Number(chainId) })
                })
            })
            .catch((error) => {
                if (error.code === 4001) {
                    myReject({ code: 4001, message: 'User denied to connect account.' })
                } else if (error.code === -32002) {
                    myReject({
                        code: -32002,
                        message: 'Resource unavailable. Something might already been processing.',
                    })
                } else {
                    myReject({ code: 500, message: 'Something went wrong.' })
                }
            })
    })
}

export { isMetaMaskInstall, connectMetaMaskAccount }
