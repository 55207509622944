import Cookies from 'universal-cookie'
import axios from '../action/MintServerApi'
import errorConstant from '../component/page/ConnectAccountPage/constant/ConnectErrorConstant'
import { signData } from './signatureUtil'
import { connector, disconnect } from './walletUtil'

const checkRegister = (address) => {
    return axios.get(`/rest/register/${address}`)
}

const registerOrLogin = async ({
    checkRegisterResult,
    connectionToken,
    email,
    address,
    selectWalletType,
    toSignMessage,
}) => {
    let isRegistered = checkRegisterResult
    if (checkRegisterResult === null || checkRegisterResult === undefined) {
        // check register first if not sent by param, if already register, then login directly
        const checkRegisterRes = await checkRegister(address)
        isRegistered = checkRegisterRes.data ? checkRegisterRes.data : false
    }

    let apiPath = `/rest/register`
    if (isRegistered) {
        apiPath = `/rest/login`
    }
    //sign data and register
    return new Promise((onSuccess, onError) => {
        signData({
            message: toSignMessage,
            address: address,
            connector: connector,
            type: selectWalletType,
        })
            .then((registerSignature) => {
                axios
                    .post(apiPath, {
                        address: address,
                        email: email,
                        toSignMessage: toSignMessage,
                        sig: {
                            r: registerSignature.r,
                            s: registerSignature.s,
                            v: registerSignature.v,
                        },
                        connectionToken: connectionToken,
                    })
                    .then((res) => {
                        console.log('register res=', res)
                        let userInfo
                        if (isRegistered) {
                            userInfo = res.data
                        } else {
                            userInfo = res.data.clientInfo
                        }
                        const cookies = new Cookies()
                        cookies.set('userInfo', userInfo, { path: '/' })
                        onSuccess(userInfo)
                    })
                    .catch((error) => {
                        console.log('register error', error.response)
                        var errorRes = error.response.data
                        if (
                            errorRes.error.includes('invalid connection token') ||
                            errorRes.error.includes('connection token is null') ||
                            errorRes.error.includes('connectionToken has been used for register')
                        ) {
                            onError(errorConstant.connectionTokenExpired)
                        } else if (errorRes.error.includes('account disabled')) {
                            onError(errorConstant.accountDisabled)
                        } else {
                            onError(errorConstant.somethingWentWrong)
                        }
                    })
            })
            .catch((error) => {
                if (error.message?.includes('User denied message signature')) {
                    onError(errorConstant.userDenySignature)
                } else if (error.message?.includes('Request params invalid')) {
                    onError(errorConstant.signFail)
                } else if (error.message?.includes('disconnected')) {
                    cancelWalletConnect()
                    onError(errorConstant.walletHasBeenDisconnected)
                } else {
                    onError(errorConstant.somethingWentWrong)
                }
                console.log('register sign fail', error)
            })
    })
}

const cancelWalletConnect = () => {
    try {
        disconnect()
    } catch (error) {
        console.log('disconnect from wallet fail', error)
    }
}

export { checkRegister, registerOrLogin }
