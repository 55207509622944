import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'
import { styled, Box, IconButton, Menu, MenuItem, ListItemIcon, Typography } from '@mui/material'
import LanguageIcon from '@mui/icons-material/Language'
import CheckIcon from '@mui/icons-material/Check'
import languageConstant from '../../../constant/LanguageConstant'

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    '&:hover': {
        backgroundColor: theme.palette.divider,
    },
    '&.Mui-selected&:hover': {
        backgroundColor: theme.palette.divider,
    },
    '&.Mui-selected': {
        backgroundColor: 'transparent',
    },
}))

const LanguageDropdown = () => {
    const cookies = new Cookies()
    const { i18n } = useTranslation()
    const [isLanguageMenuShown, setIsLanguageMenuShown] = useState(false)
    const [currentLanguage, setCurrentLanguage] = useState(languageConstant['en'])
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (key) => {
        i18n.changeLanguage(key)
        cookies.set('language', key, { path: '/' })
        setCurrentLanguage(languageConstant[key])
        setAnchorEl(null)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        const i18nLng = i18n.language
        const baseLng = i18nLng.slice(0, 2).toLocaleLowerCase()
        console.log({ i18nLng, baseLng })
        if (baseLng === 'fr') {
            setCurrentLanguage(languageConstant['fr'])
        } else if (baseLng === 'en') {
            setCurrentLanguage(languageConstant['en'])
        } else if (baseLng === 'de') {
            setCurrentLanguage(languageConstant['de'])
        } else if (baseLng === 'es') {
            setCurrentLanguage(languageConstant['es'])
        } else if (baseLng === 'ru') {
            setCurrentLanguage(languageConstant['ru'])
        } else if (baseLng === 'it') {
            setCurrentLanguage(languageConstant['it'])
        } else {
            setCurrentLanguage(languageConstant[i18nLng])
        }
    }, [i18n])

    const onButtonClick = (event) => {
        setIsLanguageMenuShown(!isLanguageMenuShown)
        setAnchorEl(event.currentTarget)
    }

    return (
        <>
            <Box>
                <IconButton
                    id="language-button"
                    aria-controls={open ? 'language-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{ backgroundColor: isLanguageMenuShown ? 'divider' : null }}
                    onClick={onButtonClick}
                >
                    <LanguageIcon fontSize="small" sx={{ color: 'text.primary' }} />
                    {isLanguageMenuShown && (
                        <Menu
                            id="language-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'language-button',
                            }}
                        >
                            {Object.keys(languageConstant).map((key, index) => (
                                <CustomMenuItem
                                    id={`language-item-${index}`}
                                    key={key}
                                    selected={languageConstant[key] === currentLanguage}
                                    onClick={() => handleClick(key)}
                                >
                                    <ListItemIcon>
                                        {languageConstant[key] === currentLanguage && <CheckIcon fontSize="small" />}
                                    </ListItemIcon>
                                    <Typography sx={{ fontWeight: languageConstant[key] === currentLanguage && 600 }}>
                                        {languageConstant[key]}
                                    </Typography>
                                </CustomMenuItem>
                            ))}
                        </Menu>
                    )}
                </IconButton>
            </Box>
        </>
    )
}

export default LanguageDropdown
