import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Typography, Box, Tooltip, ClickAwayListener } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ReactComponent as EthereumIcon } from '../../../../image/ic_blockchain_ethereum.svg'
import { ReactComponent as MaticIcon } from '../../../../image/polygon-matic-filled-logo.svg'
import { cryptoPricetoUsd } from '../../../../util/ethUtil'
import { convertFixedEstimatedFee } from '../../../../util/numberUtil'
import { isMobileDevice } from '../../../../util/mobileUtil'

const EstimatedCost = ({ wholeEstimatedFee, currencyUnit }) => {
    const { t } = useTranslation()
    const [usdEstimatedFee, setUsdEstimatedFee] = useState('N/A')
    const fixedEstimatedFee = convertFixedEstimatedFee(wholeEstimatedFee)
    const [tooltipOpen, setTooltipOpen] = useState(false)

    useEffect(() => {
        cryptoPricetoUsd(wholeEstimatedFee, currencyUnit).then((result) => {
            if (result < 0) {
                setUsdEstimatedFee('N/A')
            } else {
                setUsdEstimatedFee(result)
            }
        })
    }, [wholeEstimatedFee])

    const handleTooltipClose = () => {
        setTooltipOpen(false)
    }

    const handleTooltipOpen = () => {
        setTooltipOpen(true)
        if (tooltipOpen === true) {
            setTooltipOpen(false)
        }
    }

    const renderTooltip = () => {
        const isMobileView = isMobileDevice()

        return isMobileView ? (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={tooltipOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={t('failedToGetEstimatedCostTooltipHint')}
                        placement="top"
                        arrow
                    >
                        <ErrorOutlineIcon fontSize="small" color="warning" onClick={handleTooltipOpen} />
                    </Tooltip>
                </div>
            </ClickAwayListener>
        ) : (
            <Tooltip title={t('failedToGetEstimatedCostTooltipHint')} placement="right">
                <ErrorOutlineIcon fontSize="small" color="warning" />
            </Tooltip>
        )
    }

    return (
        <>
            <Box display="flex" alignItems="center" gap={0.5}>
                <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
                    {t('estimatedCost')}
                </Typography>
                {wholeEstimatedFee === null && renderTooltip()}
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
                <Box width={24} height={24} display="flex" justifyContent="center" alignItems="center">
                    {currencyUnit && currencyUnit.toLocaleLowerCase() === 'matic' ? (
                        <MaticIcon width={23} height={20} />
                    ) : (
                        <EthereumIcon />
                    )}
                </Box>
                <Typography variant="body1" color="info.main" sx={{ fontWeight: 500 }}>
                    {fixedEstimatedFee}
                </Typography>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                    {`${currencyUnit || 'ETH'}`}
                </Typography>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                    ≈
                </Typography>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                    $
                </Typography>
                <Typography variant="body1" color="info.main" sx={{ fontWeight: 500 }}>
                    {usdEstimatedFee}
                </Typography>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 500 }}>
                    USD
                </Typography>
            </Box>
        </>
    )
}

EstimatedCost.propTypes = {
    wholeEstimatedFee: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currencyUnit: PropTypes.string.isRequired,
}

export default EstimatedCost
