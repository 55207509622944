import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, Box, CardMedia, Stack, Button, Link } from '@mui/material'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'

const MediaPreview = ({ info }) => {
    const { t } = useTranslation()
    const isLaptopScreen = useMediaQuery((theme) => theme.breakpoints.up('laptop'))

    const getYoutubeEmbedUrl = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
        const match = url.match(regExp)
        console.log('match: ', match)
        if (match && match[2].length === 11) {
            return `https://www.youtube.com/embed/${match[2]}`
        } else {
            return null
        }
    }

    return (
        <Box>
            {info.previewImgUrl && (
                <img src={info.previewImgUrl} width={isLaptopScreen ? '100%' : '90%'} style={{ maxWidth: 480 }} />
            )}
            {info.previewYoutubeUrl && (
                <Box sx={{ maxWidth: 480 }}>
                    <CardMedia
                        component="iframe"
                        width="100%"
                        height="280"
                        frameBorder="0"
                        allowFullScreen
                        src={getYoutubeEmbedUrl(info.previewYoutubeUrl)}
                    />
                </Box>
            )}
            {info.previewAnimationUrl && (
                <Stack mt={1} spacing={1} alignItems="flex-start">
                    <Button
                        variant="outlined"
                        size="large"
                        startIcon={<OndemandVideoIcon />}
                        href={info.previewAnimationUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('clickToViewVideo')}
                    </Button>
                    <Link
                        variant="body1"
                        href={info.previewAnimationUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="text.secondary"
                    >
                        {info.previewAnimationUrl}
                    </Link>
                </Stack>
            )}
        </Box>
    )
}

MediaPreview.propTypes = {
    info: PropTypes.object.isRequired,
}

export default MediaPreview
