import React from 'react'
import { Box, Typography, Link, Button, Paper } from '@mui/material'

import { useTranslation } from 'react-i18next'

import RwdContainer from '../common/RwdContainer'
import { isMobileDevice } from '../../util/mobileUtil'

import checkNetworkMobileImg from '../../image/BuyCryptoInstruction/checkNetworkMobile.png'
import checkNetworkDesktopImg from '../../image/BuyCryptoInstruction/checkNetworkDesktop.png'
import buyOrReceiveMobileImg from '../../image/BuyCryptoInstruction/buyOrReceiveMobile.png'
import buyOrReceiveDesktopImg from '../../image/BuyCryptoInstruction/buyOrReceiveDesktop.png'

const imgRwdBoxStyle = {
    minWidth: 180,
    maxWidth: 280,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '16px 16px 0 0',
}

const BuyCryptoInstruction = () => {
    const { t } = useTranslation()

    const goBackPage = () => {
        history.back()
    }

    const isMobile = isMobileDevice()

    return (
        <RwdContainer>
            <Box display="flex" flexDirection="column" height="100%" p={3} id="buy-cryptocurrency-page">
                <Typography variant="h3" color="textPrimary" sx={{ pl: 2 }}>
                    {t('howToBuyCryptoTitle')}
                </Typography>
                <Box component="ol">
                    <Box id="check-environment" sx={{ mt: 1 }}>
                        <Typography variant="body1" color="textPrimary" component="li" sx={{ mb: 1 }}>
                            {t('checkEnvironmentAndMoreInfoPrefix')}
                            <Link href="https://metamask.io/buy-crypto/" target="_blank" rel="noopener noreferrer">
                                {`[${t('metaMaskOfficialDocLinkText')}]`}
                            </Link>
                            {t('checkEnvironmentAndMoreInfoPostfix')}
                        </Typography>
                        <Box sx={imgRwdBoxStyle} component={Paper}>
                            {isMobile ? (
                                <img
                                    id="check-environment-img"
                                    alt="checkNetworkMobileImg"
                                    src={checkNetworkMobileImg}
                                    width="100%"
                                    height="100%"
                                />
                            ) : (
                                <img
                                    id="check-environment-img"
                                    alt="checkNetworkDesktopImg"
                                    src={checkNetworkDesktopImg}
                                    width="100%"
                                    height="100%"
                                />
                            )}
                        </Box>
                    </Box>
                    {isMobile && (
                        <Box id="use-walletconnect" sx={{ mt: 4 }}>
                            <Typography variant="body1" color="textPrimary" component="li">
                                {t('buyCryptoOnMetaMask')}
                            </Typography>
                            <Box component="ul" sx={{ pl: 3 }}>
                                <Typography variant="body1" color="textPrimary" component="li" sx={{ mt: 1 }}>
                                    <Link href="https://metamask.app.link" target="_blank" rel="noopener noreferrer">
                                        {t('useMobileContent1')}
                                    </Link>
                                </Typography>
                                <Typography variant="body1" color="textPrimary" component="li" sx={{ mt: 1 }}>
                                    {t('useMobileContent2')}
                                </Typography>
                                <Typography variant="body1" color="textPrimary" component="li" sx={{ mt: 1, mb: 1 }}>
                                    {t('useMobileContent3')}
                                </Typography>
                                <Box sx={imgRwdBoxStyle} component={Paper}>
                                    <img
                                        id="buy-or-receive-mobile-img"
                                        alt="buyOrReceiveMobileImg"
                                        src={buyOrReceiveMobileImg}
                                        width="100%"
                                        height="100%"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {!isMobile && (
                        <Box id="use-metamask" sx={{ mt: 4 }}>
                            <Typography variant="body1" color="textPrimary" component="li">
                                {t('buyCryptoOnMetaMask')}
                            </Typography>
                            <Box component="ul" sx={{ pl: 3 }}>
                                <Typography variant="body1" color="textPrimary" component="li" sx={{ mt: 1 }}>
                                    {t('useExtensionContent1')}
                                </Typography>
                                <Typography variant="body1" color="textPrimary" component="li" sx={{ mt: 1 }}>
                                    {t('useExtensionContent2')}
                                </Typography>
                                <Typography variant="body1" color="textPrimary" component="li" sx={{ mt: 1, mb: 1 }}>
                                    {t('useExtensionContent3')}
                                </Typography>
                            </Box>
                            <Box sx={imgRwdBoxStyle} component={Paper}>
                                <img
                                    id="buy-or-receive-desktop-img"
                                    alt="buyOrReceiveDesktopImg"
                                    src={buyOrReceiveDesktopImg}
                                    width="100%"
                                    height="100%"
                                />
                            </Box>
                        </Box>
                    )}
                    <Box id="wait-transaction" sx={{ mt: 4 }}>
                        <Typography variant="body1" color="textPrimary" component="li">
                            {t('waitTransaction')}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button onClick={goBackPage} variant="outlined" color="secondary" sx={{ mt: 3, mb: 7 }}>
                        {t('gotItButton')}
                    </Button>
                </Box>
            </Box>
        </RwdContainer>
    )
}

export default BuyCryptoInstruction
